

// Variables

$red: #CE2127;
$dk-grey: #2F3133;
$lt-grey: #EFEFEF;

// Fonts

@font-face {
  font-family: 'apple garamond';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/AppleGaramond-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'apple garamond';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/AppleGaramond-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'apple garamond';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/AppleGaramond.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'apple garamond';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/AppleGaramond-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'apple garamond';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/AppleGaramond-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'apple garamond';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/AppleGaramond-BoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}



@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/SourceSans3-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/SourceSans3-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/SourceSans3-Italic.ttf') format('ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/SourceSans3-SemiBold.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: url('/wp-content/themes/clc/dist/fonts/SourceSans3-Bold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NeueHelveticaPro55Roman";
  src: url('/wp-content/themes/clc/dist/fonts/NeueHelveticaPro55Roman/font.woff2') format('woff2'), url('webFonts/NeueHelveticaPro55Roman/font.woff') format('woff');
}
@font-face {
  font-family: "NeueHelveticaPro77CondensedBold";
  src: url('/wp-content/themes/clc/dist/fonts/NeueHelveticaPro77CondensedBold/font.woff2') format('woff2'), url('webFonts/NeueHelveticaPro77CondensedBold/font.woff') format('woff');
}
@font-face {
  font-family: "NeueHelveticaPro75Bold";
  src: url('/wp-content/themes/clc/dist/fonts/NeueHelveticaPro75Bold/font.woff2') format('woff2'), url('webFonts/NeueHelveticaPro75Bold/font.woff') format('woff');
}
@font-face {
  font-family: "NeueHelveticaPro55Roman";
  font-weight: 500;
  src: url('/wp-content/themes/clc/dist/fonts/NeueHelveticaPro75Bold/font.woff2') format('woff2'), url('webFonts/NeueHelveticaPro75Bold/font.woff') format('woff');
}


$serif: apple garamond, garamond, serif;
$sanserif: NeueHelveticaPro55Roman, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
$sanserif_bold: NeueHelveticaPro75Bold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
$sanserif_condensed_bold: NeueHelveticaPro77CondensedBold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;

.serif, .garamond {
  font-family: $serif;
}
.san-serif, .sanserif, .helvetica {
  font-family: $sanserif;
}


//

.has-clc-red-background-color {
  background-color: $red !important;
}
.has-clc-darkgrey-background-color {
  background-color: $dk-grey !important;
}
.has-clc-lightgrey-background-color {
  background-color: $lt-grey !important;
}

// General Layout

html, body {
  font-family: $sanserif;
}

.container {
  max-width: 1550px;
  width: 90%;
}

.alignfull {
	margin: 32px calc(50% - 50vw);
	max-width: 100vw;
	width: 100vw;
}
.wp-block-cover {
  overflow-y: visible !important;
}
.wp-block-column {
  &.left-divider {
    border-left: 5px solid $dk-grey;
    padding-left: 3%;
  }
}
.section-header {
  text-transform: uppercase;
}

.offset-up-300-wrapper {
  margin-top: 300px !important;
}
.offset-up-200-wrapper {
  margin-top: 200px !important;
}
.offset-up-300 {
  margin-top: -300px !important;
}
.offset-up-200 {
  margin-top: -200px !important;
}
.offset-up-100 {
  margin-top: -100px !important;
  @media screen and (max-width: 650px){
    margin-top: -50px !important;
  }
}
.wp-block-embed iframe {
  width: 100%;
}
.wp-block-button .wp-block-button__link.is-style-outline,
.wp-block-button.is-style-outline>.wp-block-button__link {
  border-width: 1px !important;
}

h1, .wp-block-cover h1 {
  font-family: $sanserif_condensed_bold;
  font-size: 50px;
  margin: 80px auto 60px;
  font-weight: bold;
  &:not(.has-text-color) {
    color: #000000;
  }
}

h2, .wp-block-cover h2 {
  font-family: $sanserif_condensed_bold;
  font-size: 35px;
  margin: 60px auto 10px;
  font-weight: bold;
  text-transform: uppercase;
  &:not(.has-text-color) {
    color: #4D4D4D;
  }
  &.wp-block-heading {
    @media screen and (max-width: 1000px){
      margin-top: 0;
      text-align: center;
      font-size: 25px;
    }
  }
}

h3, .wp-block-cover h3 {
  font-family: $serif;
  font-weight: 100;
  font-size: 25px;
  margin: 40px auto 30px;
  &:not(.has-text-color) {
    color: $red;
  }
}

h4, .wp-block-cover h4 {
  font-family: $sanserif_bold;
  font-size: 18px;
  font-weight: bold;
  margin: 30px 0 25px;
  text-transform: uppercase;
  &:not(.has-text-color) {
    color: $red;
  }
}

h5, .wp-block-cover h5 {
  font-family: $serif;
  font-weight: 100;
  font-size: 16px;
  margin: 30px 0 20px;
  &:not(.has-text-color) {
    color: $dk-grey;
  }
}

body.page {
  p, ul, li, div, .wp-block-button__link {
    font-size: 16px;
  }
}
.wp-block-button {
  color: #FFF;
}
hr {
  opacity: 1;
}

// Components

hr.wp-block-separator {
  border-color: $red;
  margin-left: 0;
  margin-right: 0;
  border-width: 3px;
  &.small {
    width: 138px;
  }
  &.dkgrey {
    border-color: #393E42;
  }
}

.vertical-rule {
  &::after {
    content: " ";
    display: block;
    width: 4px;
    height: 100%;
    background-color: $dk-grey;
    margin: 0 auto;
  }
}


// Header

header {
  &.header {
    z-index: 2;
    position: relative;
    &.scrolled {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #FFF;
      box-shadow: 0 10px 30px #999;
      transition: all 1s;
    }
    .container {
      width: 100%;
      .row {
        .left-col, .right-col {
          align-self: center;
          display: flex;
          align-items: center;
        }
        .left-col {
          .header__logo {
            img {
              height: 138px;
              width: 138px;
              margin-bottom: -20px;
              @media screen and (max-width: 786px){
                height: 130px;
                width: 130px;
                margin-bottom: 0px;
              }
            }
          }
          .nav-wrapper {
            flex: 1;
            margin-top: 25px;
            margin-left: 25px;
          }
        }
        .right-col {
          justify-content: flex-end;
          padding-top: 25px;
        }
      }
    }
  }
  .nav-wrapper {
    .header__navigation {
      width: 100%;
      nav.navbar {
        padding: 0;
        .container {
          background-color: none;
          margin: 0;
          padding: 0;
          ul#menu-primary {
            flex-wrap: nowrap;
            li.menu-item {
              position: unset;
              a {
                text-transform: uppercase;
                font-family: NeueHelveticaPro75Bold;
                font-size: 16px;
                color: $dk-grey;
                font-weight: bold;
                padding: 10px;
                text-decoration: none;
              }
              &.menu-item-has-children {
                a {
                  &.dropdown-toggle {
                    i {
                      transition: transform .2s;
                    }
                    &::after {
                      display: none !important;
                    }
                  }
                }
                &:hover {
                  ul.dropdown-menu {
                    display: block;
                    width: 700px;
                    border-radius: 0;
                    border: none;
                    left: -25px;
                    top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 25px 60px;
                    max-width: 160%;
                    li.menu-item {
                      flex: 0 0 50%;
                      &.page-about {
                        flex: 0 0 33%;
                      }
                      a {
                        font-family: $sanserif;
                        font-weight: normal;
                        text-transform: capitalize;
                        font-size: 18px;
                        color: #4D4D4D;
                        &:active {
                          background-color: $lt-grey;
                        }
                      }
                    }
                  }
                }
              }
              &:hover {
                &>a {
                  color: $red;
                  &.dropdown-toggle {
                    i {
                      transform: rotate(180deg);
                      transition: transform .2s;
                    }
                  }
                }
              }
            }
            &>li.menu-item:not(.menu-item-has-children) {
              @media screen and (max-width: 900px){
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .responsive-nav {
    display: block;
    padding: 0;
    background-color: $dk-grey;
    color: #FFFFFF;
    position:absolute;
    top: -100vh;
    opacity: 0;
    left: 0;
    width: 100%;
    transition: all .4s;
    &.active {
      top: 0;
      opacity: 1;
      transition: all .4s;
    }
    .mobile-nav-section {
      padding: 15px 30px;
    }
    .close-wrapper {
      text-align: right;
      background-color: $red;
      .close {
        text-transform: uppercase;
        i {
          margin-left: 6px;
        }
      }
    }
    .search-wrapper {
      background-color: $red;
      .search {
        display: block;
        border: none;
        padding-left: 0;
        display: flex;
        flex-direction: row;
        input#search-input {
          position: relative;
          min-width: 97%;
        }
        i {
          color: #FFFFFF;
        }
      }
    }
    .mobile-menu {
      padding-bottom: 0;
      ul.nav {
        &>li.menu-item {
          border-bottom: 1px solid $red;
          &>a {
            text-transform: uppercase;
            font-weight: bold;
            padding-top: 16px;
            padding-bottom: 16px;
            color: #FFFFFF;
            &.dropdown-toggle::after {
              display: none;
            }
            i {
              float: right;
              margin-top: 4px;
              transition: transform .3s;
            }
          }
          &.clicked {
            &>a {
              i {
                color: $red;
                transform: rotate(180deg);
                transition: transform .3s;
              }
            }
          }
          ul.dropdown-menu {
            background-color: transparent;
            color: #FFF;
            border: none;
            padding-top: 0;
            li.menu-item {
              a {
                color: #FFFFFF;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
    .center-wrapper {
      padding-top: 0;
      padding-bottom: 0;
      .center {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        color: #FFFFFF;
        border-bottom: 1px solid $red;
        font-size: 18px;
        margin-right: 0;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
    .donate-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: right;
      .donate {
        display: inline-block;
        color: #FFFFFF;
        border-color: #FFFFFF;
      }
    }
  }
  &.is-center {
    .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item a {
      font-weight: 400;
    }
  }
  .center {
    font-size: 16px;
    text-transform: uppercase;
    color: $dk-grey;
    text-decoration: none;
    margin-right: 25px;
    @media screen and (max-width: 1100px){
      display: none;
    }
  }
  .search {
    border-left: 1px solid $dk-grey;
    padding-left: 25px;
    position: relative;
    @media screen and (max-width: 1300px){
      display: none;
    }
    i {
      color: #4D4D4D;
      z-index: 5;
      position: relative;
      padding: 10px 10px;
      height: 40px;
      background-color: transparent;
      cursor: pointer;
      transition: background-color .3s;
    }
    &.active {
      i {
        transition: background-color .3s;
        background-color: $red;
      }
    }
    input#search-input {
      position: absolute;
      right: 0px;
      top: 0px;
      border: none;
      border-radius: 0;
      height: 40px;
      width: 0;
      padding: 6px 0;
      z-index: 3;
      transition: all .3s;
    }
    &.active {
      input#search-input {
        transition: all .3s;
        padding: 6px 9px;
        width: 450px;
      }
    }
  }
  .donate {
    font-size: 16px;
    text-transform: uppercase;
    color: $dk-grey;
    border: 1px solid $dk-grey;
    border-radius: 30px;
    padding: 3px 23px;
    text-decoration: none;
    margin-left: 25px;
    @media screen and (max-width: 1300px){
      display: none;
    }
  }
  button.navbar-toggler {
    display: none;
    background-color: $red;
    color: #FFFFFF;
    border-radius: 0;
    padding: 10px 20px;
    text-transform: uppercase;
    &:focus {
      border: none;
      box-shadow: unset;
    }
    @media screen and (max-width: 1300px){
      display: flex;
      align-items: center;
    }
    i {
      font-size: 29px;
      margin-right: 13px;
    }
  }
  &.is-home:not(.scrolled), &.is-center:not(.scrolled) {
    margin-bottom:-130px;
    transition: margin-bottom 1s;
    a.nav-link, .center, .search i, .donate {
      color: #FFFFFF !important;
      transition: color 1s;
    }
    .search {
      border-left-color: #FFFFFF;
      transition: border-left-color 1s;
    }
    .donate {
      border-color: #FFFFFF;
      transition: border-color 1s;
    }
    button.navbar-toggler {
      background-color: transparent;

    }
  }

}
body.admin-bar.scrolled {
  &.home, &.center {
    header.header {
      top: 32px;
      @media screen and (max-width: 650px){
        top: 0;
      }
    }
  }
}

// Main Body

main {
  &.main {
    .page-hero {
      background-color: $lt-grey;
      height: 400px;
      padding: 50px 6%;
      text-align: center;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      hr {
        margin: 0 auto;
        display: block;
        width: 140px;
        border-top: 6px solid $red;
      }
      h1 {
        font-size: 60px;
        @media screen and (max-width: 650px){
          font-size: 50px;
        }
        font-weight: bold;
        color: #FFF;
        display: inline-block;
        padding-top: 6px;
        margin: 30px auto 0;
      }
      &.no-image {
        h1 {
          color: $dk-grey;
        }
      }
    }
    .main-content {
      padding: 48px 0 0;
      article {
        p, ul li {
          color: #707070;
        }
        .wp-block-cover.alignfull.hero {
          @media screen and (max-width: 1000px){
            text-align: center;
          }
        }
        .wp-block-cover.alignfull.hero, .wp-block-cover.alignfull.home-hero {
          padding-top: 120px;
          // transition: padding-top .5s;
          // &.undocked {
          //   padding-top: 0;
          //   transition: padding-top .5s;
          // }
        }
      }
    }
  }
}

// Footer

footer {
  &.footer {
    padding: 65px 0;
    a.header__logo {
      float: left;
      margin-right: 90px;
      @media screen and (max-width: 650px){
        width: 50%;
        margin-right: 30px;
      }
      img {
        width: 200px;
        height: 200px;
        max-width: 100%;
        @media screen and (max-width: 650px){
          width: 100%;
          height: unset;
        }
      }
    }
    .menu-footer-container {
      @media screen and (max-width: 1000px){
        margin-bottom: 60px;
      }
    }
    ul#menu-footer {
      margin: 20px 0 20px 30px;
      @media screen and (max-width: 650px){
        margin-top: 0;
      }
      li.menu-item {
        display: block;
        width: 100%;
        padding: 6px 0;
        &:first-child {
          @media screen and (max-width: 650px){
            padding-top: 0;
          }
        }
        a {
          color: #FFFFFF;
          font-weight: 300;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
    .header {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 7px;
    }
    .phone, .email, .address {
      font-size: 16px;
      line-height: 33px;
      font-weight: 300;
    }
    .email {
      @media screen and (max-width: 650px){
        margin-bottom: 20px;
      }
    }
    .social-links {
      display: flex;
      margin-top: 15px;
      @media screen and (max-width: 650px){
        margin-top: 30px;
        justify-content: center;
      }
      flex-wrap: wrap;
      .social {
        border: 1px solid #FFFFFF;
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 0 8px 8px 0;
      }
    }
  }
}

// Home page

body.home {
  main.main {
    .main-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .home-hero {
    min-height: 850px;
    margin-top: 0;
    @media screen and (max-width: 650px){
      padding-bottom: 80px;
    }
    .home-hero-items-column {
      opacity: 0;
      margin-bottom: -850px;
    }
    // .home-hero-hr, .home-hero-title, .home-hero-button, .home-hero-items {
    //   //transition: all .3s;
    //   opacity: 0;
    //   margin-bottom: -100px;
    //   //transform: translate(0, 150px);
    // }
    .wp-block-columns {
      margin-left: 6% !important;
      @media screen and (max-width: 1000px){
        flex-direction: column;
        text-align: center;
        padding: 0 10%;
        margin-left: 0 !important;
      }
      .wp-block-buttons {
        .wp-block-button {
          @media screen and (max-width: 1000px){
            margin: 0 auto;
          }
        }
      }
    }
    hr.wp-block-separator {
      @media screen and (max-width: 1000px){
        margin: 0 auto;
      }
    }
    .wp-block-spacer {
      @media screen and (max-width: 1000px){
        display: none;
      }
    }
  }
  .who-we-are {
    @media screen and (max-width: 1000px){
      flex-direction: column-reverse !important;
    }
  }
  .home-announcement {
    margin-top: -150px;
    align-self: flex-start;
    @media screen and (max-width: 1000px){
      margin-top: -96px;
      padding: 0 !important;
      width: 100%;
    }
    margin-bottom: 0px;
    z-index: 1;
    .announcement-nav {
      z-index: 1;
      margin-left: 370px;
      margin-top: 190px;
      margin-bottom: -220px;
      z-index: 1;
      position: relative;
      @media screen and (max-width: 1000px){
        float: right;
        margin: unset;
        margin-left: -110px;
        padding: 15px;
        font-size: 27px;
      }
      @media screen and (max-width: 650px){
        margin-top: 33px;
      }
      i {
        padding: 5px 8px;
        cursor: pointer;
        @media screen and (max-width: 650px){
          background-color: $red;
          border-radius: 100%;
          box-shadow: 1px 1px 10px -3px #000;
        }
      }
    }
    #announcements {
      .announcement {
        @media screen and (max-width: 1000px){
          padding-bottom: 20px;
        }
        img {
          width: 350px;
          @media screen and (max-width: 1000px){
            display: none;
          }
        }
        hr {
          border-color: #FFFFFF;
          border-width: 2.5px;
          margin-top: 0;
          @media screen and (max-width: 1000px){
            display: none;
          }
        }
        .header {
          font-family: NeueHelveticaPro75Bold;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 10px;
          @media screen and (max-width: 1000px){
            border-bottom: 4px solid #FFFFFF;
            padding: 17px 21px;
          }
        }
        p {
          font-size: 18px;
          margin-bottom: 8px;
          font-weight: 300;
          color: #FFFFFF;
          @media screen and (max-width: 1000px){
            padding: 17px 21px;
          }
        }
        a {
          font-weight: bold;
          text-decoration: underline;
          @media screen and (max-width: 1000px){
            margin: 0px 21px 20px;
          }
        }
      }
    }
  }
  .home-our-solutions {
    @media screen and (max-width: 1000px){
      flex-direction: column;
    }
    &>.wp-block-column {
      opacity: 0;
      margin-top: 400px;
      margin-bottom: -400px;
      @media screen and (max-width: 1000px){
        display: flex;
        flex-direction: row;
        border-bottom: 5px solid $red;
      }
      @media screen and (max-width: 650px){
        flex-direction: column;
      }
      figure.wp-block-image {
        margin-bottom: -30px;
        margin-right: -30px;
        margin-left: 30px;
        @media screen and (max-width: 1000px){
          margin: 0;
          position: relative;
          overflow: hidden;
        }
        img {
          box-shadow: 0 10px 20px #666;
          @media screen and (max-width: 1000px){
            box-shadow: unset;
            bottom: 0;
            min-height: 100%;
            max-width: unset;
          }
          @media screen and (max-width: 650px){
            width: 100%;
          }
        }
      }
      .wp-block-group {
        background-color: #fff;
        padding-top: 25px;
        padding-bottom: 20px;
        z-index: 1;
        position: relative;
        @media screen and (min-width: 999px){
          border-bottom: 5px solid $dk-grey;
          padding: 1px 9% 0;
        }
        @media screen and (max-width: 650px){
          padding-top: 0px;
        }
        h4 {
          margin-bottom: 25px;
          margin-top: 25px;
          @media screen and (max-width: 1000px){
            padding-left: 20px;
          }
        }
        p {
          @media screen and (max-width: 1000px){
            padding-left: 20px;
          }
        }
        .wp-block-buttons {
          @media screen and (max-width: 1000px){
            position: absolute;
            right: 0;
            bottom: 0;
          }
          .wp-block-button {
            a.wp-block-button__link {
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23cd1d25" d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z"/></svg>');
              background-position: right center;
              background-size: 25px;
              background-repeat: no-repeat;
              color: transparent !important;
              margin-right: 20px;
              @media screen and (max-width: 1000px){
                margin-right: 0;
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }
  }
  .home-our-experts {
    @media screen and (min-width: 786px){
      margin-bottom: -150px;
    }
    .experts-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .slick-track {
        // margin: 0 -15px;
      }
      .card {
        display: flex;
        align-items: center;
        border-radius: 0;
        border: none;
        background-color: $red;
        flex: 0 0 30%;
        padding: 30px;
        margin-right: 15px;
        margin-left: 15px;
        @media screen and (max-width: 786px){
          background-color: transparent;
          padding: 30px 0 0;
          margin-right: 0;
          margin-left: 0;
        }
        .headshot-wrapper {
          border: 1px solid transparent;
          border-radius: 100%;
          padding: 5px;
          &:hover {
            border-color: #FFFFFF;
          }
          .headshot {
            a {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
        .bio-wrapper {
          margin-top: 18px;
          width: 100%;
          .name {
            font-family: $serif;
            font-weight: 100;
            text-align: left;
            @media screen and (max-width: 786px){
              text-align: center;
            }
            text-transform: capitalize;
            font-size: 32px;
            a {
              color: #FFFFFF;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .publications {
          width: 100%;
          margin-top: 5px;
          @media screen and (max-width: 786px){
            background-color: $red;
            padding: 5px 5% 20px;
          }
          .publication {
            @media screen and (max-width: 786px){
              padding: 10px 15px;
            }
            hr {
              height: 2px;
            }
            &:first-child {
              hr {
                @media screen and (max-width: 786px){
                  display: none;
                }
              }
            }
            .type {
              font-style: italic;
              font-size: 16px;
              text-transform: capitalize;
              font-family: $serif;
              margin: 20px 0 8px;
              @media screen and (max-width: 786px){
                text-align: center;
              }
            }
            .title {
              a {
                text-decoration: none;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 16px;
                &:hover {
                  text-decoration: underline;
                }
                @media screen and (max-width: 786px){
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .btn.slick-arrow {
        display: flex;
        position: absolute;
        top: 215px;
        padding: 0;
        margin: 0;
        z-index: 9;
        &.prev {
          left: -60px;
          @media screen and (max-width: 786px){
            left: 0px;
          }
        }
        &.next {
          right: -60px;
          @media screen and (max-width: 786px){
            right: 0px;
          }
        }
        i {
          background-color: #4D4D4D;
          padding: 13px;
          color: #FFF;
          height: 40px;
          align-self: center;
          font-size: 30px;
          display: table;
        }
      }
    }
  }
  .home-featured-experts {
    overflow-y: visible;
    padding: 0;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 120px;
    @media screen and (max-width: 786px){
      margin-bottom: 0;
    }
    .wp-block-columns {
      @media screen and (max-width: 786px){
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 0;
      }
    }
    h2 {
      color: #FFFFFF;
      margin-bottom: 35px;
      margin-top: 50px;
    }
    .experts-left-col {
      padding-left: 6% !important;
    }
    .experts-right-col {
      margin-bottom: -100px;
      padding-right: 6% !important;
    }
    .expert {
      display: flex;
      align-items: center;
      .headshot {
        overflow:hidden;
        border-radius: 100%;
        width: 160px;
        height: 160px;
        background-repeat: no-repeat;
        background-position: center center;
        float: left;
      }
      &.featured {
        flex-direction: row-reverse;
        justify-content: space-between;
        @media screen and (max-width: 1000px){
          flex-direction: column;
        }
        .headshot-wrapper {
          width: 300px;
          height: 300px;
          margin: 0 50px;
          border: 1px solid #fff;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          flex: 0 0 300px;
          .headshot {
            width: 280px;
            height: 280px;
            margin: auto;
          }
        }
        .bio-wrapper {
          padding-right: 20px;
          .name {
            font-family: $serif;
            font-weight: 100;
            font-size: 40px;
            margin-bottom: 10px;
            text-transform: none;
            @media screen and (max-width: 1000px){
              text-align: center;
            }
          }
          .position {
            font-family: $sanserif_bold;
            font-size: 22px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 20px;
            font-style: normal;
            font-family: $sanserif;
            @media screen and (max-width: 1000px){
              text-align: center;
            }
          }
          .button {
            background-color: $red;
            color: #FFFFFF;
            border-radius: 6px;
            padding: 12px 22px;
            text-decoration: none;
            text-transform: uppercase;
            display:inline-block;
            font-weight: 300;
            @media screen and (max-width: 1000px){
              margin: 0 auto;
              display: block;
              float: none;
              width: 170px;
              display: block;
            }
          }
        }
      }
      .name {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: bold;
        a {
          font-family: $serif;
          text-decoration: none;
          color: inherit;
        }
      }
      .position{
        font-family: $serif;
        font-style: italic;
        font-size: 22px;
        font-weight: 100;
      }
    }
    hr.wp-block-separator {
      border-color: #FFF;
      border-width: 2.5px;
    }
  }
}

//
// Call To Action
//
#call-to-action {
  width: 100%;
  background-color: #FFFFFF;
  margin: 90px auto 0;
  padding: 90px 10%;
  @media screen and (max-width: 1000px){
    margin: 40px auto;
    padding: 30px 4%;
    max-width: 100% !important;
  }
  @media screen and (max-width: 650px){
    margin-bottom: 0;
  }
  .row {
    @media screen and (max-width: 1000px){
      flex-direction: column;
    }
    &>div {
      &:nth-child(even) {
        @media screen and (min-width: 650px){
          padding-left: 30px;
        }
      }
      &:nth-child(odd) {
          padding-right: 30px;
      }
      align-self: center;

      .ctct-form-wrapper {
        a.ctct-button, .ctct-disclosure {
          display: none;
        }
        form.clc-sign-up {
          display: flex;
          @media screen and (max-width: 650px){
            flex-direction: column;
          }
          p.ctct-form-field.ctct-form-field-email {
            flex: 1;
            input.ctct-email {
              width: 97%;
              @media screen and (max-width: 650px){
                width: 100%;
              }
              border-radius: 0;
              border: 1px solid #393E42;
              height: 50px;
              padding: 10px 20px;
            }
          }
          p.ctct-form-field.ctct-form-field-submit {
            flex: 0 0 150px;
            @media screen and (max-width: 650px){
              flex: unset;
            }
            input.ctct-submit {
              background-color: $red;
              color: #FFFFFF;
              width: 100%;
              height: 50px;
              border: none;
              border-radius: 6px;
              text-transform: uppercase;
              font-weight: 300;
            }
          }
        }
      }
    }
    .cta-text {
      font-size: 35px;
      color: $red;
      text-align: right;
      font-family: $serif;
      font-weight: 100;
      @media screen and (max-width: 1000px){
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
      }
    }
  }
}

//
//
//
.research-slider-wrapper, .blog-slider-wrapper {
  position: unset;
  h2.wp-block-heading {
    padding-left: 90px;
    @media screen and (max-width: 1600px){
      padding-left: 6%;
    }
    @media screen and (max-width: 1000px){
      margin-top: 50px;
    }

  }
}
h2.slider-header {
  margin-left: 5%;
  padding-left: 10px;
  @media screen and (max-width: 786px){
    margin-left: 0;
    padding-left: 0;
  }
}
.scrolling-list {
  position: relative;
  @media screen and (max-width: 1000px){
    padding-bottom: 100px;
  }
  @media screen and (max-width: 650px){
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
  &#research_slider, &#publications_slider {
    .wrapper {
      .card {
        min-height: 500px;
        flex: 0 0 25%;
        .date {
          margin-bottom: 20px;
        }
      }
    }
  }
  &#publications_slider {
    .wrapper {
      .card {
        hr {
          background-color: #ce2127;
        }
        .title {
          font-size: 22px;
          font-weight: bold;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        .desc {
          padding: 0 24px;
          font-size: 18px;
        }
        .tags {
          margin-top: 10px;
        }
      }
    }
  }
  a.button.browse-all {
    margin-top: -85px;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: unset;
    @media screen and (max-width: 650px){
      margin:0 50px;
    }
    &.no-slider {
      justify-content: center;
      .card {
        flex: 0 0 23%;
        min-width: 350px;
      }
    }
    .card {
      //height: 350px;
      cursor: pointer;
      border-radius: 0;
      padding: 0;
      margin: 0 10px;
      @media screen and (max-width: 650px){
        // margin: 0 25px;
        margin: 0;
      }
      box-shadow: 2px 2px 7px -4px #666;
      display: flex;
      flex-direction: column;
      .image {
        width: 100%;
        height: 415px;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        background-position: center top;
        a {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .tags {
        font-size: 0;
        padding: 0 24px;
      }
      hr {
        background-color: $dk-grey;
        border-color: transparent;
        height: 5px;
        margin: 24px;
      }
      .date {
        font-family: $serif;
        font-weight: 100;
        font-size: 20px;
        padding: 0 24px;
        margin-bottom: 0px;
      }
      .title {
        padding: 0 24px;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 24px;
        a {
          font-family: $sanserif_bold;
          text-decoration: none;
          color: inherit;
        }
      }
      .desc {
        padding: 0 24px;
      }
      .author {
        font-family: $serif;
        font-weight: 100;
        font-size: 20px;
        padding: 0 24px;
        margin-bottom: 20px;
      }
    }
    .slick-list {
      width: calc(100% - 80px);
      margin: 0 80px;
      // width: 90%;
      // margin: 0 80px;
      // @media screen and (max-width: 1100px){
      //   width: 86%;
      //   margin: 0 7%;
      // }
      // @media screen and (max-width: 800px){
      //   width: 80%;
      //   margin: 0 10%;
      // }
      @media screen and (max-width: 650px){
        width: 100%;
        margin: 0;
      }
    }
    .btn.slick-arrow {
      position: absolute;
      z-index: 1;
      top: 44%;
      background-color: $dk-grey;
      border-radius: 0;
      color: #FFF;
      padding: 20px 26px;
      font-size: 30px;
      top: 50%;
      width: 80px;
      height: 80px;
      transform: translate(0, -50%);
      @media screen and (max-width: 650px){
        width: 45px;
        height: 45px;
        padding: 9px;
        font-size: 22px;
      }
      &.prev {
        left: 0;
        // @media screen and (max-width: 650px){
        //   left: -20px;
        // }
      }
      &.next {
        right: 0;
        // @media screen and (max-width: 650px){
        //   right: -20px;
        // }
      }
    }
  }

}
.latest-blogs-row {
  overflow: visible;
  #blog_slider {
    flex-wrap: wrap;
    .card {
      //min-height: 330px;
      flex: 0 0 23%;
      hr {
        border-color: $red;
      }
    }
  }
}

a.button.browse-all {
  @media screen and (max-width: 1000px){
    position: absolute;
    bottom: 0px; /* -80px */
    width: 40%;
    text-align: center;
    left: 30%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media screen and (max-width: 650px){
    width: 60%;
    left: 20%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
a.button.browse-all, button.facetwp-load-more {
  border-radius: 6px;
  border: 1px solid $red;
  text-transform: uppercase;
  color: $red;
  padding: 12px 80px 8px;
  float: right;
  text-decoration: none;
  font-size: 17px;
  margin-right: 6%;
}
button.facetwp-load-more {
  background-color: transparent;
  margin: 40px auto;
  display: block;
  float: none;
}

//
//
//
#subpage_list {
  margin-top: -100px;
  .card {
    display: block;
    flex: 0 0 48%;
    @media screen and (max-width: 782px){
      flex: 0 0 96%;
    }
    padding: 0;
    border-radius: 0;
    border: none;
    margin-bottom: 50px;
    box-shadow: 0px 0px 15px -7px #666;
    .image {
      min-height: 270px;
      a {
        width: 100%;
        min-height: 270px;
        display: block;
      }
    }
    .title {
      float: left;
      @media screen and (max-width: 650px){
        width: 70%;
      }
      a {
        font-size: 30px;
        text-transform: uppercase;
        padding: 40px;
        display: block;
        color: #4D4D4D;
        text-decoration: none;
        font-weight: bold;
        @media screen and (max-width: 650px){
          font-size: 25px;
          padding: 29px 16px;
          line-height: 30px;
        }
      }
    }
    .arrow {
      background-color: $red;
      float: right;
      @media screen and (max-width: 650px){
        width: 30%;
      }
      a {
        padding: 40px;
        color: #FFFFFF;
        display: block;
        font-size: 30px;
        @media screen and (max-width: 650px){
          padding: 30px 20px;
          text-align: center;
          line-height: 22px;
        }
      }
    }
  }
}

//
// About / History
//
.history-video-section {
  position: relative;
  z-index: 2;
  &.wp-block-columns {
    .wp-block-columns {
      margin-left: 5%;
      .wp-block-embed {
        iframe {
          width: 100%;
          height: 350px;
        }
      }
    }
  }
}
.red-graphic-section {
  position: relative;
  z-index: 1;
}
.original-co-authors.wp-block-cover {
  margin-top: -100px;
  z-index: 0;
  position: relative;
  padding-top: 100px;
  h2 {
    margin-bottom: 30px;
  }
  hr.wp-block-separator {
    border-color: #393E42;
    margin-top: 0;
    opacity: 1;
  }
}

// About / History

.team-list {
  .wrapper {
    .card {
      &:hover {
        .headshot-wrapper {
          border: 1px solid $red;
        }
      }
      background-color: transparent;
      border: none;
      margin-bottom: 60px;
      @media screen and (max-width: 1000px){
        box-shadow: none;
        height: unset;
      }
      .headshot-wrapper {
        margin-bottom: 40px;
        width: 85%;
        margin: 0 auto;
        border-radius: 100%;
        border: 1px solid transparent;
        .headshot {
          transform: scale(.95);
          display: block;
          max-width: 300px;
          width: 100%;
          padding: 50%;
          margin: 0 auto;
          border-radius: 100%;
          overflow: hidden;
          background-color: $lt-grey;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      &:hover {
        .bio-wrapper {
          .name {
            a {
              color: $red;
            }
          }
        }
      }
      .bio-wrapper {
        .name {
          font-size: 25px;
          margin-bottom: 0;
          @media screen and (max-width: 1000px){
            text-align: center;
          }
          a {
            text-decoration: none;
            color: #4D4D4D;
            font-family: $serif;
            font-weight: 100;
          }
        }
        .position {
          font-size: 15px;
          font-weight: 500;
          color: #4D4D4D;
          @media screen and (max-width: 1000px){
            text-align: center;
          }
        }
      }
    }
  }
}
#endorsements {
  .endorsement {
    background-color: #FFFFFF;
    padding: 30px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.16);
    width: 48%;
    margin-right: 1%;
    @media screen and (max-width: 786px){
      width: 100%;
      margin-right: 0%;
    }
    display: inline-block;
    margin-bottom: 20px;
    &:nth-child(even){
      margin-left: 1%;
    }
    .title {
      color: $red;
      text-transform: uppercase;
      font-size: 24px;
      margin-bottom: 8px;
      font-weight: bold;
    }
    .desc {
      font-size: 20px;
      color: #393E42;
    }
    hr {
      background-color: #4D4D4D;
      height: 4px;
      margin: 20px 0 30px;
    }
    .date, .publication {
      font-size: 20px;
      font-family: $serif;
      font-weight: 100;
      color: #393E42;
    }
  }
}

.page-nav.wp-block-buttons {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-evenly !important;
  .wp-block-button {
    position: relative;
    display: flex;
    align-items: center;
    @media screen and (max-width: 650px){
      width: 100%;
    }
    .wp-block-button__link {
      font-weight: bold;
      font-size: 20px;
      padding: 10px 100px 10px 15px !important;
      text-transform: uppercase;
      @media screen and (max-width: 650px){
        text-align: left;
      }
      &::after {
        content: " ";
        display: block;
        width: 60px;
        height: 60px;
        background-color: #4D4D4D;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="%23ffffff" d="M174.6 472.6c4.5 4.7 10.8 7.4 17.4 7.4s12.8-2.7 17.4-7.4l168-176c9.2-9.6 8.8-24.8-.8-33.9s-24.8-8.8-33.9 .8L216 396.1 216 56c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 340.1L41.4 263.4c-9.2-9.6-24.3-9.9-33.9-.8s-9.9 24.3-.8 33.9l168 176z"/></svg>');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }
}

//


.single-team {
  .headshow-wrapper {
    margin: -80px auto 0;
    display: block;
    width: 420px;
    height: 420px;
    border: 1px solid red;
    border-radius: 100%;
    overflow: hidden;
    padding: 9px;
    @media screen and (max-width: 650px){
      width: 320px;
      height: 320px;
    }
    .headshot {
      width: 400px;
      height: 400px;
      @media screen and (max-width: 650px){
        width: 300px;
        height: 300px;
      }
      display: block;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 100%;
      overflow: hidden;
    }
  }
  .sidebar {
    .header {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
      color: $dk-grey;
      margin-bottom: 20px;
    }
    a.back-to.back-to-staff {
      color: #4D4D4D;
      text-transform: uppercase;
      font-weight: 600;
      i {
        margin-right: 2px;
      }
    }
    .position {
      font-family: $serif;
      font-weight: 100;
      font-size: 25px;
      color: $red;
      margin-bottom: 30px;
      line-height: 110%;
    }
    .linkedin {
      @media screen and (max-width: 650px){
        float: right;
        margin: -20px 0 20px;
      }
      a {
        width: 50px;
        height: 50px;
        display: block;
        border: 1px solid $red;
        border-radius: 100%;
        padding: 10px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        i {
          font-size: 24px;
          color: $red;
        }
      }
    }
  }
}

//

.wp-block-social-links {
  li.wp-social-link {
    margin-right: 5px;
    a {
      border: 1px solid $red;
      border-radius: 100%;
      padding: 9px !important;
      svg {
        fill: $red !important;
        font-size: 24px;
      }
    }
  }
}

//

.red-box-shadow {
  img {
    box-shadow: 100px 70px 0 0 $red;
  }
}
.blue-box-shadow {
  img {
    box-shadow: 100px 70px 0 0 #0D6EA6;
  }
}

//
.our-values {
  .wp-block-column {
    @media screen and (max-width: 650px){
      text-align: center;
    }
  }
  hr.wp-block-separator {
    border-width: .5px;
    margin-bottom: 40px;
  }
}
#jobopenings {
  .job {
    background-color: #FFFFFF;
    box-shadow: 0 0 10px -5px #666;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    padding: 30px;
    .title {
      color: $red;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .desc {

    }
    hr {
      border-color: $dk-grey;
      margin-bottom: 30px;
      border-width: 2.5px;
    }
    button {
      a {
        color: #FFFFFF;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

//
.single-job {
  .sidebar {
    .field {
      &.notice {
        font-size: 16px;
        font-style: italic;
      }
    }
  }
}

//

.social-wrapper {
  margin-top: 25px;
  .heateor_sss_sharing_ul {
    a {
      margin-right: 10px !important;
      .heateor_sss_svg {
        background-color: #FFFFFF !important;
        border: 1px solid $red !important;
        width: 40px !important;
        height: 40px !important;
        padding: 5px !important;
        svg {
          path {
            fill: $red !important;
          }
        }
      }
    }
  }
}

//
.single-event {
  .speakers {
    .header {
      font-family: $serif;
      font-weight: 100;
      color: $red;
      font-size: 28px;
      margin-bottom: 10px;
    }
    .headshot {
      width: 150px;
      height: 150px;
      display: block;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 10px;
    }
    .name {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      color: $dk-grey;
      margin-bottom: 5px;
    }
    .title {
      color: #707070;
      font-size: 20px;
    }
  }
  .embed {
    margin-top: 60px;
  }
  .sidebar {
    .field {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}


a.back-to {
  display: block;
  margin-top: 20px;
  color: $red;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
  i {
    margin-left: -8px;
    margin-right: 8px;
  }
}

//

.sidebar {
  .header {
    font-family: $serif;
    color: $red;
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 100;
    margin-bottom: 20px;
  }
  .field {
    color: #707070;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

//
.events-page {
  .tabs {
    margin-top: -90px;
    @media screen and (max-width: 650px){
      margin-top: -130px;
      display: flex;
      flex-direction: column;
      margin-right: auto;
      margin-left: auto;
      align-content: space-around;
    }
    .tab {
      font-family: $serif;
      font-weight: 100;
      background-color: #FFFFFF;
      text-align: center;
      font-size: 28px;
      padding: 20px 0;
      color: #4D4D4D;
      cursor: pointer;
      @media screen and (max-width: 650px){
        width: 70%;
      }
      &.current {
        background-color: $red;
        color: white;
      }
    }
  }
  .tab-content {
    .card {
      background-color: #FFFFFF;
      border: none;
      border-radius: 0;
      box-shadow: 0px 0px 10px -5px #666;
      padding: 0;
      display: flex;
      flex-direction: row;
      margin-top: 50px;
      @media screen and (max-width: 650px){
        flex-direction: column;
      }
      .image {
        flex: 0 0 300px;
        height: 300px;
        background-color: $lt-grey;
      }
      .content {
        flex: 1;
        padding: 30px 50px;
        @media screen and (max-width: 650px){
          padding: 30px;
        }
        .title {
          color: $red;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 24px;
          margin-bottom: 10px;
          a {
            color: inherit;
            text-decoration: none;
          }
        }
        .desc {
          font-size: 18px;
          margin-bottom: 20px;
          color: #393E42;
        }
        hr {
          border-color: #4D4D4D;
          margin: 30px 0;
        }
        .date, .address {
          font-family: $serif;
          font-weight: 100;
          color: #393E42;
        }
        button.wp-block-button {
          border: none;
          background-color: transparent;
          text-transform: uppercase;
          float: right;
          @media screen and (max-width: 650px){
            float: left;
            padding-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

//
.border-bottom {
  border-bottom: 5px solid $dk-grey !important;
}
.thin-black {
  border-color: #000 !important;
  border-width: .5px !important;
}

//
.partner-icon.wp-block-media-text {
  display: block;
  grid-template-columns: unset !important;
  grid-template-rows: unset !important;
  float: left;
  width: 100%;
  figure {
    grid-column: unset !important;
    grid-row: unset !important;
    float: left;
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }
  .wp-block-media-text__content {
    margin-top: 2px;
    @media screen and (max-width: 1300px) and (min-width: 1001px){
      margin-left: 5px;
    }
    @media screen and (max-width: 1000px) and (min-width: 786px){
      margin-left: 10px;
    }
  }
}
.partner-icon-legend {
  display: flex;
  .wp-block-media-text__content {
    padding-left: 15px !important;
    float: left;
    p {
      margin-bottom: 0;
    }
  }
}

//

.parent-our-solutions {
  .why-it-matters {
    &>.row {
      background-color: $lt-grey;
      margin: 100px 0;
      .icon {
        width: 230px;
        height: 230px;
        padding: 65px;
        margin-top: -30px;
        margin-bottom: 30px;
        @media screen and (max-width: 786px){
          position: absolute;
          left: 0;
          z-index: 3;
          padding: 30px;
          width: 130px;
          height: 130px;
        }
        @media screen and (max-width: 575px){
          width: 100px;
          height: 100px;
          padding: 10px;
        }
        img {
          width: 100px;
          height: 100px;
          @media screen and (max-width: 786px){
            width: 75px;
            height: 75px;
          }
        }
      }
      .content-wrapper {
        background-color: #FFFFFF;
        padding: 28px;
        margin-left: 20px;
        margin-top: 50px;
        border-bottom: 4px solid $dk-grey;
        margin-bottom: -30px;
        @media screen and (max-width: 786px){
          margin-left: 0;
        }
        .title {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .content-grid-spacer {
    @media screen and (max-width: 786px){
      display: none;
    }
  }
  .image-offset-box.wp-block-column {
    @media screen and (max-width: 786px){
      margin: 0 -6% !important;
      padding: 90px 30px;
    }
    figure.wp-block-image {
      @media screen and (max-width: 786px){
        float: right;
        max-width: 50%;
        margin-right: -4%;
        margin-top: -150px;
        margin-bottom: -90px;
        margin-left: 0;
      }
    }
  }
  .scrolling-list {
    margin-bottom: -175px;
    &#blog_slider {
      .wrapper {
        .btn.slick-arrow {
          top: unset;
          bottom: 143px;
          transform: unset;
        }
      }
    }
    &#research_slider {
      .wrapper {
        .btn.slick-arrow {
          top: unset;
          bottom: 160px;
          transform: unset;
        }
      }
    }
    a.button.browse-all {
      bottom: -60px;
    }
  }
  #blog_slider {
    &.scrolling-list {
      .wrapper {
        .card {
          hr {
            border-width: 2.5px;
          }
        }
      }
    }
  }
  .research-slider-wrapper, .blog-slider-wrapper {
    h2.wp-block-heading {
      margin-bottom: 30px;
    }
  }
}

.image-offset-box.wp-block-column {
  margin-left: 70px;
  margin-top: 70px;
  margin-right: -70px;
  padding: 30px;
  figure.wp-block-image {
    margin-left: -160px;
    margin-top: -180px;
  }
  hr {
    @media screen and (max-width: 786px){
      display: none;
    }
  }
  .wp-block-media-text {
    width: 200px;
    @media screen and (max-width: 786px){
      display: flex;
      flex-direction: column;
    }
    .wp-block-media-text__content {
      padding-left: 0;
      @media screen and (max-width: 786px){
        align-self: flex-start;
      }
    }
    .wp-block-media-text__media {
      @media screen and (max-width: 786px){
        align-self: flex-start;
      }
      img {
        @media screen and (max-width: 786px){
          width: 50px;
        }
        @media screen and (max-width: 650px){
          margin: 20px 0;
        }
      }
    }
  }
  p {
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
    a {
      text-decoration: none;
    }
  }
  hr {
    border-color: #FFFFFF;
    margin-bottom: 20px;
    border-width: 2px;
  }
}

.signatories {
  .row {
    .col-3 {
      margin: 15px 0;
    }
  }
  .name {
    font-size: 27px;
    color: #4D4D4D;
    margin-bottom: 5px;
  }
  .position {
    font-size:18px;
    color: #4D4D4D;
    font-weight: bold;
  }
}

//
.listing-template {
  .report.card, .news.card, .search-result.card {
    border:none;
    border-radius: 0px;
    box-shadow: 0 0 14px -8px #666;
    margin-bottom: 25px;
    display:flex;
    flex-direction: row;

    .image-wrapper {
      @media screen and (max-width: 768px){
        display: none;
      }
      background-color: $lt-grey;
      width: 300px;
      padding: 0;
      .image {
        display: block;
        width: 100%;
        height: 100%;
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .content-wrapper {
      flex: 1;
      padding: 30px;
      .tags {
        margin-bottom: 10px;
        @media screen and (max-width: 768px){
          display: none;
        }
      }
      .title {
        color: $red;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 15px;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      .desc {
        font-size: 18px;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      hr {
        border-color: $dk-grey;
        margin: 20px 0;
      }
      .date, .author {
        font-family: $serif;
        font-weight: 100;
      }
      .date {
        margin-bottom: 0;
      }

    }
  }
}

.tags {
  a {
    display: inline-block;
    font-size: 14px;
    background-color: $dk-grey;
    color: #FFFFFF;
    border-radius: 20px;
    text-decoration: none;
    padding: 5px 12px 3px;
    margin: 0 10px 10px 0;
  }
}

.sidebar-wrapper {
  background-color:$lt-grey;
  padding: 20px;
  width: 90%;
  margin-top: -90px;
  @media screen and (max-width: 768px){
    width: 104%;
    margin: -6px -2% 30px;
  }
  .header {
    text-transform: uppercase;
    font-weight: bold;
    color: #4D4D4D;
    margin-bottom: 10px;
  }
  .facetwp-facet {
    margin-bottom: 20px;
    .facetwp-input-wrap {
      width: 100%;
    }
    input.facetwp-search {
      background-color: transparent;
      border: none;
      border-bottom: 5px solid $dk-grey;
      padding: 10px 10px 10px 35px !important;
      min-width: unset;
      width: 100%;
    }
    i.facetwp-icon {
      right: unset;
      left: 0;
      opacity: 1;
      &:before {
        background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="26.686" height="27.256" viewBox="0 0 26.686 27.256"><path id="noun-search-icon-3670603-FFFFFF" d="M273.2,263.5c-1.794-1.885-3.679-3.724-5.5-5.518-.772-.749-1.521-1.5-2.294-2.248.068-.114.136-.227.2-.318a9.911,9.911,0,1,0-18.416-5.087v.023a9.878,9.878,0,0,0,14.669,8.652l4.019,4.019c.431.431.885.908,1.317,1.362.885.908,1.771,1.862,2.748,2.7a2.129,2.129,0,0,0,1.476.59A2.515,2.515,0,0,0,273.2,263.5Zm-18.689-5.722a7.92,7.92,0,1,1,8.7-2.43,7.947,7.947,0,0,1-8.7,2.43Zm17.258,7.721a.39.39,0,0,1-.454.091,2.074,2.074,0,0,1-.5-.5,3.935,3.935,0,0,0-.272-.318l-6.949-6.949c.2-.182.409-.363.59-.545l7.335,7.335a1,1,0,0,0,.159.136,1.7,1.7,0,0,1,.2.2.533.533,0,0,1-.114.545Z" transform="translate(-247.195 -240.425)" fill="%23cd1e25"/></svg>');
      }
    }
    span.facetwp-counter {
      display: none;
    }
    .facetwp-display-value {
      font-family: $serif;
      font-weight: 100;
    }
  }
  button.facetwp-reset {
    border: 1px solid $red;
    border-radius: 6px;
    color: $red;
    padding: 10px 40px;
    text-transform: uppercase;
  }
}

.single-report, .single-blog {
  .thumbnail {
    img {
      width: 90%;
      margin: 40px 0;
      display: block;
      box-shadow: 0px 0px 15px -7px #666;
    }
  }
  div {
    .author {
      font-family: $serif;
      font-weight: 100;
      color: $red;
      font-size: 28px;
      margin-bottom: 10px;
    }
    .originally-published {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 25px;
      color: #4D4D4D;
    }
    .content {
      margin-bottom: 60px;
    }
    .report-links {
      margin-bottom: 30px;
      .report-link {
        a {
          text-transform: uppercase;
          font-weight: 300;
        }
      }
    }
    hr.wp-block-separator {
      border-width: 0.5px;
    }
    .key-takeaways {
      .header {
        font-weight: bold;
        text-transform: uppercase;
        color: $dk-grey;
        font-size: 24px;
        margin-bottom:10px;
      }
      margin-bottom: 60px;
    }
    .downloads {
      .downloads-link {
        margin-bottom: 20px;
        a {
          width: 250px;
        }
      }
    }
  }
  .author {
    color: red;
    margin-bottom: 15px;
    font-size: 28px;
  }
  .date {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: $dk-grey;
  }
}

.back-to-publications {
  margin-bottom: 10px;
  margin-top: 0;
}

.single-post, .single-appearance {
  .author {
    font-family: $serif;
    font-weight: 100;
    color: $red;
    margin-bottom: 15px;
    font-size: 28px;
  }
  .date {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #4D4D4D;
  }
  .content {
    color: #707070;
  }
}

.content {
  .wp-block-embed {
    iframe {
      min-height: 600px;
    }
  }
}



a.wp-block-button__link.wp-element-button {
  padding: 17px 20px 14px !important;
  &:hover {
    color: inherit;
  }
  &.outline {
    background-color: #FFFFFF;
    border: 1px solid $red;
    color: $red;
  }
}

.single-appearance {
  .video_link {
    text-align: center;
    margin: 35px 0;
  }
  .social-wrapper {
    float: right;
  }
  a.back-to {
    float: left;
  }
  .tags {
    float: left;
    margin: 27px 0 -20px 20px;
  }
}

//
.single-press_release, .single-op_ed {
  .sidebar {
    .tags {
      margin: 30px 0;
    }
    .contact-info {
      color: $dk-grey;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .note {
      font-style: italic;
      color: #707070;
    }
    .field {
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      color: $dk-grey;
      margin-bottom: 20px;
    }
    .logo {
      margin: 50px 0 40px;
      img {
        width: 100%;
      }
    }
  }
  .originally-published {
    color: #4D4D4D;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
}

.single-op_ed {
  .authors {
    font-family: $serif;
    color: $red;
    font-size: 24px;
  }
  .download-link.wp-block-button {
    float: right;
    a {
      text-transform: uppercase;
    }
  }
}

.center-for-climate-and-trade {
  header.header {
    a.center {
      font-weight: 800;
      border-bottom: 6px solid red;
    }
  }
  .center-cta {
    @media screen and (max-width: 786px){
      text-align: center;
    }
  }
  main.main {
    .main-content {
      padding-top: 0;
      padding-bottom: 0;
      .hero {
        margin-top: 0;
        .wp-block-columns {
          margin-left: 6% !important;
        }
      }
    }
  }
  .center-nav.page-nav.wp-block-buttons {
    @media screen and (max-width: 1000px){
      display: none;
    }
  }
  .image-offset-box {
    @media screen and (max-width: 1000px){
      display: none;
    }
  }
  .ctct-form-wrapper {
    p.ctct-form-field.ctct-form-field-email {
      text-align: right;
      input.ctct-email {
        border-radius: 0;
        padding: 5px 9px;
        border: 1px solid $dk-grey;
      }
    }
    p.ctct-form-field.ctct-form-field-submit {
      text-align: right;
      input.ctct-submitted {
        background-color: #38375B;
        color: #FFFFFF;
        border: none;
        border-radius: 6px;
        text-transform: uppercase;
        padding: 8px 25px;
      }
    }
    .ctct-disclosure {
      display: none;
    }
  }
  .sidebar-wrapper {
    align-self: flex-start;
  }
}

.donate-buttons-wrapper {
  .wp-block-button {
    button.wp-block-button__link {
      width: 100%;
      background-color: transparent !important;
    }
  }
}

cite {
  font-style: normal;
  color: #4D4D4D;
}

.contact-form-wrapper {
  form {
    input.wpcf7-form-control.wpcf7-submit {
      background-color: $red;
      border: none;
      color: #FFFFFF;
      padding: 10px 25px;
      text-transform: uppercase;
      border-radius: 6px;
    }
  }
}

.search-results-page {
  .search-box-wrapper {
    span.facetwp-input-wrap {
      width: 100%;
      input.facetwp-search {
        width: 100%;
        border-radius: 0px;
        border: 1px solid #000;
        padding: 10px 15px;
        font-size: 20px;
      }
    }
  }
  .facetwp-type-pager {
    text-align: center;
    button.facetwp-load-more {
      background-color: transparent;
      color: $red;
      border: 1px solid $red;
      border-radius: 6px;
      padding: 10px 70px;
      margin: 40px auto 0;
    }
  }
}

.contact-form {
  @media screen and (max-width:650px){
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
  .row {
    padding: 8px 0;
    @media screen and (max-width:925px){
      display: block;
    }
    @media screen and (max-width:650px){
      padding: 15px 0;
    }
    &:nth-child(even) {
      background-color: $lt-grey;
    }
    p {
      margin-bottom: 0;
      color: $dk-grey !important;
    }
    .wpcf7-form-control {
      width: 100%;
    }
    input.wpcf7-form-control.wpcf7-submit {
      margin: 0 auto;
      display: block;
    }
    span.wpcf7-not-valid-tip {
      background-color: red;
      color: white;
      display: inline-block;
      padding: 0px 6px;
      border-radius: 16px;
      text-transform: uppercase;
      font-size: 11px;
      float: right;
      margin-top: -24px;
      z-index: 1;
      position: relative;
    }
  }
}

.has-data-link {
  cursor: pointer;
}

.latest-research-row {
  margin-bottom: 0;
  //@media screen and (max-width: 990px){
    padding-right: 0 !important;
    padding-left: 0 !important;
  //}
  .wp-block-columns {
    //@media screen and (max-width: 990px){
      width: 100%;
      margin-right: auto !important;
      margin-left: auto !important;
    //}
  }
}

.sidebar-wrapper {
  .collapsible {
    @media screen and (max-width: 768px){
      border-bottom: 1px solid #000;
      margin-bottom: 13px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
    .header {
      @media screen and (max-width: 768px){
        margin: 0;
        padding: 15px 0;
        cursor: pointer;
      }
      i {
        @media screen and (min-width: 767px){
          display: none;
        }
        float: right;
        font-size: 25px;
        transform: rotate(90deg);
        transition: transform .2s;
      }
      &.show {
        i {
          transform: rotate(0);
          transition: transform .2s;
        }
      }
    }
    .body {
      @media screen and (max-width: 768px){
        display: none;
      }
      &.show {
        display: block !important;
      }
    }
  }
}
