@font-face {
  font-family: "apple garamond";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/AppleGaramond-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "apple garamond";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/AppleGaramond-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "apple garamond";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/AppleGaramond.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "apple garamond";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/AppleGaramond-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "apple garamond";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/AppleGaramond-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "apple garamond";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/AppleGaramond-BoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/SourceSans3-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/SourceSans3-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/SourceSans3-Italic.ttf") format("ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/SourceSans3-SemiBold.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: url("/wp-content/themes/clc/dist/fonts/SourceSans3-Bold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "NeueHelveticaPro55Roman";
  src: url("/wp-content/themes/clc/dist/fonts/NeueHelveticaPro55Roman/font.woff2") format("woff2"), url("webFonts/NeueHelveticaPro55Roman/font.woff") format("woff");
}
@font-face {
  font-family: "NeueHelveticaPro77CondensedBold";
  src: url("/wp-content/themes/clc/dist/fonts/NeueHelveticaPro77CondensedBold/font.woff2") format("woff2"), url("webFonts/NeueHelveticaPro77CondensedBold/font.woff") format("woff");
}
@font-face {
  font-family: "NeueHelveticaPro75Bold";
  src: url("/wp-content/themes/clc/dist/fonts/NeueHelveticaPro75Bold/font.woff2") format("woff2"), url("webFonts/NeueHelveticaPro75Bold/font.woff") format("woff");
}
@font-face {
  font-family: "NeueHelveticaPro55Roman";
  font-weight: 500;
  src: url("/wp-content/themes/clc/dist/fonts/NeueHelveticaPro75Bold/font.woff2") format("woff2"), url("webFonts/NeueHelveticaPro75Bold/font.woff") format("woff");
}
.serif, .garamond {
  font-family: apple garamond, garamond, serif;
}

.san-serif, .sanserif, .helvetica {
  font-family: NeueHelveticaPro55Roman, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
}

.has-clc-red-background-color {
  background-color: #CE2127 !important;
}

.has-clc-darkgrey-background-color {
  background-color: #2F3133 !important;
}

.has-clc-lightgrey-background-color {
  background-color: #EFEFEF !important;
}

html, body {
  font-family: NeueHelveticaPro55Roman, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
}

.container {
  max-width: 1550px;
  width: 90%;
}

.alignfull {
  margin: 32px calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.wp-block-cover {
  overflow-y: visible !important;
}

.wp-block-column.left-divider {
  border-left: 5px solid #2F3133;
  padding-left: 3%;
}

.section-header {
  text-transform: uppercase;
}

.offset-up-300-wrapper {
  margin-top: 300px !important;
}

.offset-up-200-wrapper {
  margin-top: 200px !important;
}

.offset-up-300 {
  margin-top: -300px !important;
}

.offset-up-200 {
  margin-top: -200px !important;
}

.offset-up-100 {
  margin-top: -100px !important;
}
@media screen and (max-width: 650px) {
  .offset-up-100 {
    margin-top: -50px !important;
  }
}

.wp-block-embed iframe {
  width: 100%;
}

.wp-block-button .wp-block-button__link.is-style-outline,
.wp-block-button.is-style-outline > .wp-block-button__link {
  border-width: 1px !important;
}

h1, .wp-block-cover h1 {
  font-family: NeueHelveticaPro77CondensedBold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
  font-size: 50px;
  margin: 80px auto 60px;
  font-weight: bold;
}
h1:not(.has-text-color), .wp-block-cover h1:not(.has-text-color) {
  color: #000000;
}

h2, .wp-block-cover h2 {
  font-family: NeueHelveticaPro77CondensedBold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
  font-size: 35px;
  margin: 60px auto 10px;
  font-weight: bold;
  text-transform: uppercase;
}
h2:not(.has-text-color), .wp-block-cover h2:not(.has-text-color) {
  color: #4D4D4D;
}
@media screen and (max-width: 1000px) {
  h2.wp-block-heading, .wp-block-cover h2.wp-block-heading {
    margin-top: 0;
    text-align: center;
    font-size: 25px;
  }
}

h3, .wp-block-cover h3 {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  font-size: 25px;
  margin: 40px auto 30px;
}
h3:not(.has-text-color), .wp-block-cover h3:not(.has-text-color) {
  color: #CE2127;
}

h4, .wp-block-cover h4 {
  font-family: NeueHelveticaPro75Bold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 30px 0 25px;
  text-transform: uppercase;
}
h4:not(.has-text-color), .wp-block-cover h4:not(.has-text-color) {
  color: #CE2127;
}

h5, .wp-block-cover h5 {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  font-size: 16px;
  margin: 30px 0 20px;
}
h5:not(.has-text-color), .wp-block-cover h5:not(.has-text-color) {
  color: #2F3133;
}

body.page p, body.page ul, body.page li, body.page div, body.page .wp-block-button__link {
  font-size: 16px;
}

.wp-block-button {
  color: #FFF;
}

hr {
  opacity: 1;
}

hr.wp-block-separator {
  border-color: #CE2127;
  margin-left: 0;
  margin-right: 0;
  border-width: 3px;
}
hr.wp-block-separator.small {
  width: 138px;
}
hr.wp-block-separator.dkgrey {
  border-color: #393E42;
}

.vertical-rule::after {
  content: " ";
  display: block;
  width: 4px;
  height: 100%;
  background-color: #2F3133;
  margin: 0 auto;
}

header.header {
  z-index: 2;
  position: relative;
}
header.header.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 10px 30px #999;
  transition: all 1s;
}
header.header .container {
  width: 100%;
}
header.header .container .row .left-col, header.header .container .row .right-col {
  align-self: center;
  display: flex;
  align-items: center;
}
header.header .container .row .left-col .header__logo img {
  height: 138px;
  width: 138px;
  margin-bottom: -20px;
}
@media screen and (max-width: 786px) {
  header.header .container .row .left-col .header__logo img {
    height: 130px;
    width: 130px;
    margin-bottom: 0px;
  }
}
header.header .container .row .left-col .nav-wrapper {
  flex: 1;
  margin-top: 25px;
  margin-left: 25px;
}
header.header .container .row .right-col {
  justify-content: flex-end;
  padding-top: 25px;
}
header .nav-wrapper .header__navigation {
  width: 100%;
}
header .nav-wrapper .header__navigation nav.navbar {
  padding: 0;
}
header .nav-wrapper .header__navigation nav.navbar .container {
  background-color: none;
  margin: 0;
  padding: 0;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary {
  flex-wrap: nowrap;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item {
  position: unset;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item a {
  text-transform: uppercase;
  font-family: NeueHelveticaPro75Bold;
  font-size: 16px;
  color: #2F3133;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children a.dropdown-toggle i {
  transition: transform 0.2s;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children a.dropdown-toggle::after {
  display: none !important;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children:hover ul.dropdown-menu {
  display: block;
  width: 700px;
  border-radius: 0;
  border: none;
  left: -25px;
  top: 30px;
  display: flex;
  flex-wrap: wrap;
  padding: 25px 60px;
  max-width: 160%;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children:hover ul.dropdown-menu li.menu-item {
  flex: 0 0 50%;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children:hover ul.dropdown-menu li.menu-item.page-about {
  flex: 0 0 33%;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children:hover ul.dropdown-menu li.menu-item a {
  font-family: NeueHelveticaPro55Roman, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 18px;
  color: #4D4D4D;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item.menu-item-has-children:hover ul.dropdown-menu li.menu-item a:active {
  background-color: #EFEFEF;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item:hover > a {
  color: #CE2127;
}
header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item:hover > a.dropdown-toggle i {
  transform: rotate(180deg);
  transition: transform 0.2s;
}
@media screen and (max-width: 900px) {
  header .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary > li.menu-item:not(.menu-item-has-children) {
    display: none;
  }
}
header .responsive-nav {
  display: block;
  padding: 0;
  background-color: #2F3133;
  color: #FFFFFF;
  position: absolute;
  top: -100vh;
  opacity: 0;
  left: 0;
  width: 100%;
  transition: all 0.4s;
}
header .responsive-nav.active {
  top: 0;
  opacity: 1;
  transition: all 0.4s;
}
header .responsive-nav .mobile-nav-section {
  padding: 15px 30px;
}
header .responsive-nav .close-wrapper {
  text-align: right;
  background-color: #CE2127;
}
header .responsive-nav .close-wrapper .close {
  text-transform: uppercase;
}
header .responsive-nav .close-wrapper .close i {
  margin-left: 6px;
}
header .responsive-nav .search-wrapper {
  background-color: #CE2127;
}
header .responsive-nav .search-wrapper .search {
  display: block;
  border: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
}
header .responsive-nav .search-wrapper .search input#search-input {
  position: relative;
  min-width: 97%;
}
header .responsive-nav .search-wrapper .search i {
  color: #FFFFFF;
}
header .responsive-nav .mobile-menu {
  padding-bottom: 0;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item {
  border-bottom: 1px solid #CE2127;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item > a {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #FFFFFF;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item > a.dropdown-toggle::after {
  display: none;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item > a i {
  float: right;
  margin-top: 4px;
  transition: transform 0.3s;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item.clicked > a i {
  color: #CE2127;
  transform: rotate(180deg);
  transition: transform 0.3s;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item ul.dropdown-menu {
  background-color: transparent;
  color: #FFF;
  border: none;
  padding-top: 0;
}
header .responsive-nav .mobile-menu ul.nav > li.menu-item ul.dropdown-menu li.menu-item a {
  color: #FFFFFF;
  font-weight: 300;
}
header .responsive-nav .center-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
header .responsive-nav .center-wrapper .center {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 1px solid #CE2127;
  font-size: 18px;
  margin-right: 0;
  padding-top: 16px;
  padding-bottom: 16px;
}
header .responsive-nav .donate-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}
header .responsive-nav .donate-wrapper .donate {
  display: inline-block;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
header.is-center .nav-wrapper .header__navigation nav.navbar .container ul#menu-primary li.menu-item a {
  font-weight: 400;
}
header .center {
  font-size: 16px;
  text-transform: uppercase;
  color: #2F3133;
  text-decoration: none;
  margin-right: 25px;
}
@media screen and (max-width: 1100px) {
  header .center {
    display: none;
  }
}
header .search {
  border-left: 1px solid #2F3133;
  padding-left: 25px;
  position: relative;
}
@media screen and (max-width: 1300px) {
  header .search {
    display: none;
  }
}
header .search i {
  color: #4D4D4D;
  z-index: 5;
  position: relative;
  padding: 10px 10px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}
header .search.active i {
  transition: background-color 0.3s;
  background-color: #CE2127;
}
header .search input#search-input {
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  border-radius: 0;
  height: 40px;
  width: 0;
  padding: 6px 0;
  z-index: 3;
  transition: all 0.3s;
}
header .search.active input#search-input {
  transition: all 0.3s;
  padding: 6px 9px;
  width: 450px;
}
header .donate {
  font-size: 16px;
  text-transform: uppercase;
  color: #2F3133;
  border: 1px solid #2F3133;
  border-radius: 30px;
  padding: 3px 23px;
  text-decoration: none;
  margin-left: 25px;
}
@media screen and (max-width: 1300px) {
  header .donate {
    display: none;
  }
}
header button.navbar-toggler {
  display: none;
  background-color: #CE2127;
  color: #FFFFFF;
  border-radius: 0;
  padding: 10px 20px;
  text-transform: uppercase;
}
header button.navbar-toggler:focus {
  border: none;
  box-shadow: unset;
}
@media screen and (max-width: 1300px) {
  header button.navbar-toggler {
    display: flex;
    align-items: center;
  }
}
header button.navbar-toggler i {
  font-size: 29px;
  margin-right: 13px;
}
header.is-home:not(.scrolled), header.is-center:not(.scrolled) {
  margin-bottom: -130px;
  transition: margin-bottom 1s;
}
header.is-home:not(.scrolled) a.nav-link, header.is-home:not(.scrolled) .center, header.is-home:not(.scrolled) .search i, header.is-home:not(.scrolled) .donate, header.is-center:not(.scrolled) a.nav-link, header.is-center:not(.scrolled) .center, header.is-center:not(.scrolled) .search i, header.is-center:not(.scrolled) .donate {
  color: #FFFFFF !important;
  transition: color 1s;
}
header.is-home:not(.scrolled) .search, header.is-center:not(.scrolled) .search {
  border-left-color: #FFFFFF;
  transition: border-left-color 1s;
}
header.is-home:not(.scrolled) .donate, header.is-center:not(.scrolled) .donate {
  border-color: #FFFFFF;
  transition: border-color 1s;
}
header.is-home:not(.scrolled) button.navbar-toggler, header.is-center:not(.scrolled) button.navbar-toggler {
  background-color: transparent;
}

body.admin-bar.scrolled.home header.header, body.admin-bar.scrolled.center header.header {
  top: 32px;
}
@media screen and (max-width: 650px) {
  body.admin-bar.scrolled.home header.header, body.admin-bar.scrolled.center header.header {
    top: 0;
  }
}

main.main .page-hero {
  background-color: #EFEFEF;
  height: 400px;
  padding: 50px 6%;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
main.main .page-hero hr {
  margin: 0 auto;
  display: block;
  width: 140px;
  border-top: 6px solid #CE2127;
}
main.main .page-hero h1 {
  font-size: 60px;
  font-weight: bold;
  color: #FFF;
  display: inline-block;
  padding-top: 6px;
  margin: 30px auto 0;
}
@media screen and (max-width: 650px) {
  main.main .page-hero h1 {
    font-size: 50px;
  }
}
main.main .page-hero.no-image h1 {
  color: #2F3133;
}
main.main .main-content {
  padding: 48px 0 0;
}
main.main .main-content article p, main.main .main-content article ul li {
  color: #707070;
}
@media screen and (max-width: 1000px) {
  main.main .main-content article .wp-block-cover.alignfull.hero {
    text-align: center;
  }
}
main.main .main-content article .wp-block-cover.alignfull.hero, main.main .main-content article .wp-block-cover.alignfull.home-hero {
  padding-top: 120px;
}

footer.footer {
  padding: 65px 0;
}
footer.footer a.header__logo {
  float: left;
  margin-right: 90px;
}
@media screen and (max-width: 650px) {
  footer.footer a.header__logo {
    width: 50%;
    margin-right: 30px;
  }
}
footer.footer a.header__logo img {
  width: 200px;
  height: 200px;
  max-width: 100%;
}
@media screen and (max-width: 650px) {
  footer.footer a.header__logo img {
    width: 100%;
    height: unset;
  }
}
@media screen and (max-width: 1000px) {
  footer.footer .menu-footer-container {
    margin-bottom: 60px;
  }
}
footer.footer ul#menu-footer {
  margin: 20px 0 20px 30px;
}
@media screen and (max-width: 650px) {
  footer.footer ul#menu-footer {
    margin-top: 0;
  }
}
footer.footer ul#menu-footer li.menu-item {
  display: block;
  width: 100%;
  padding: 6px 0;
}
@media screen and (max-width: 650px) {
  footer.footer ul#menu-footer li.menu-item:first-child {
    padding-top: 0;
  }
}
footer.footer ul#menu-footer li.menu-item a {
  color: #FFFFFF;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;
}
footer.footer .header {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}
footer.footer .phone, footer.footer .email, footer.footer .address {
  font-size: 16px;
  line-height: 33px;
  font-weight: 300;
}
@media screen and (max-width: 650px) {
  footer.footer .email {
    margin-bottom: 20px;
  }
}
footer.footer .social-links {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}
@media screen and (max-width: 650px) {
  footer.footer .social-links {
    margin-top: 30px;
    justify-content: center;
  }
}
footer.footer .social-links .social {
  border: 1px solid #FFFFFF;
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 8px 8px 0;
}

body.home main.main .main-content {
  padding-top: 0;
  padding-bottom: 0;
}
body.home .home-hero {
  min-height: 850px;
  margin-top: 0;
}
@media screen and (max-width: 650px) {
  body.home .home-hero {
    padding-bottom: 80px;
  }
}
body.home .home-hero .home-hero-items-column {
  opacity: 0;
  margin-bottom: -850px;
}
body.home .home-hero .wp-block-columns {
  margin-left: 6% !important;
}
@media screen and (max-width: 1000px) {
  body.home .home-hero .wp-block-columns {
    flex-direction: column;
    text-align: center;
    padding: 0 10%;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-hero .wp-block-columns .wp-block-buttons .wp-block-button {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-hero hr.wp-block-separator {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-hero .wp-block-spacer {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  body.home .who-we-are {
    flex-direction: column-reverse !important;
  }
}
body.home .home-announcement {
  margin-top: -150px;
  align-self: flex-start;
  margin-bottom: 0px;
  z-index: 1;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement {
    margin-top: -96px;
    padding: 0 !important;
    width: 100%;
  }
}
body.home .home-announcement .announcement-nav {
  z-index: 1;
  margin-left: 370px;
  margin-top: 190px;
  margin-bottom: -220px;
  z-index: 1;
  position: relative;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement .announcement-nav {
    float: right;
    margin: unset;
    margin-left: -110px;
    padding: 15px;
    font-size: 27px;
  }
}
@media screen and (max-width: 650px) {
  body.home .home-announcement .announcement-nav {
    margin-top: 33px;
  }
}
body.home .home-announcement .announcement-nav i {
  padding: 5px 8px;
  cursor: pointer;
}
@media screen and (max-width: 650px) {
  body.home .home-announcement .announcement-nav i {
    background-color: #CE2127;
    border-radius: 100%;
    box-shadow: 1px 1px 10px -3px #000;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement #announcements .announcement {
    padding-bottom: 20px;
  }
}
body.home .home-announcement #announcements .announcement img {
  width: 350px;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement #announcements .announcement img {
    display: none;
  }
}
body.home .home-announcement #announcements .announcement hr {
  border-color: #FFFFFF;
  border-width: 2.5px;
  margin-top: 0;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement #announcements .announcement hr {
    display: none;
  }
}
body.home .home-announcement #announcements .announcement .header {
  font-family: NeueHelveticaPro75Bold;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement #announcements .announcement .header {
    border-bottom: 4px solid #FFFFFF;
    padding: 17px 21px;
  }
}
body.home .home-announcement #announcements .announcement p {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 300;
  color: #FFFFFF;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement #announcements .announcement p {
    padding: 17px 21px;
  }
}
body.home .home-announcement #announcements .announcement a {
  font-weight: bold;
  text-decoration: underline;
}
@media screen and (max-width: 1000px) {
  body.home .home-announcement #announcements .announcement a {
    margin: 0px 21px 20px;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions {
    flex-direction: column;
  }
}
body.home .home-our-solutions > .wp-block-column {
  opacity: 0;
  margin-top: 400px;
  margin-bottom: -400px;
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column {
    display: flex;
    flex-direction: row;
    border-bottom: 5px solid #CE2127;
  }
}
@media screen and (max-width: 650px) {
  body.home .home-our-solutions > .wp-block-column {
    flex-direction: column;
  }
}
body.home .home-our-solutions > .wp-block-column figure.wp-block-image {
  margin-bottom: -30px;
  margin-right: -30px;
  margin-left: 30px;
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column figure.wp-block-image {
    margin: 0;
    position: relative;
    overflow: hidden;
  }
}
body.home .home-our-solutions > .wp-block-column figure.wp-block-image img {
  box-shadow: 0 10px 20px #666;
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column figure.wp-block-image img {
    box-shadow: unset;
    bottom: 0;
    min-height: 100%;
    max-width: unset;
  }
}
@media screen and (max-width: 650px) {
  body.home .home-our-solutions > .wp-block-column figure.wp-block-image img {
    width: 100%;
  }
}
body.home .home-our-solutions > .wp-block-column .wp-block-group {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 20px;
  z-index: 1;
  position: relative;
}
@media screen and (min-width: 999px) {
  body.home .home-our-solutions > .wp-block-column .wp-block-group {
    border-bottom: 5px solid #2F3133;
    padding: 1px 9% 0;
  }
}
@media screen and (max-width: 650px) {
  body.home .home-our-solutions > .wp-block-column .wp-block-group {
    padding-top: 0px;
  }
}
body.home .home-our-solutions > .wp-block-column .wp-block-group h4 {
  margin-bottom: 25px;
  margin-top: 25px;
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column .wp-block-group h4 {
    padding-left: 20px;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column .wp-block-group p {
    padding-left: 20px;
  }
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column .wp-block-group .wp-block-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
body.home .home-our-solutions > .wp-block-column .wp-block-group .wp-block-buttons .wp-block-button a.wp-block-button__link {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23cd1d25" d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z"/></svg>');
  background-position: right center;
  background-size: 25px;
  background-repeat: no-repeat;
  color: transparent !important;
  margin-right: 20px;
}
@media screen and (max-width: 1000px) {
  body.home .home-our-solutions > .wp-block-column .wp-block-group .wp-block-buttons .wp-block-button a.wp-block-button__link {
    margin-right: 0;
    background-color: transparent !important;
  }
}
@media screen and (min-width: 786px) {
  body.home .home-our-experts {
    margin-bottom: -150px;
  }
}
body.home .home-our-experts .experts-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
body.home .home-our-experts .experts-wrapper .card {
  display: flex;
  align-items: center;
  border-radius: 0;
  border: none;
  background-color: #CE2127;
  flex: 0 0 30%;
  padding: 30px;
  margin-right: 15px;
  margin-left: 15px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card {
    background-color: transparent;
    padding: 30px 0 0;
    margin-right: 0;
    margin-left: 0;
  }
}
body.home .home-our-experts .experts-wrapper .card .headshot-wrapper {
  border: 1px solid transparent;
  border-radius: 100%;
  padding: 5px;
}
body.home .home-our-experts .experts-wrapper .card .headshot-wrapper:hover {
  border-color: #FFFFFF;
}
body.home .home-our-experts .experts-wrapper .card .headshot-wrapper .headshot a {
  display: block;
  width: 100%;
  height: 100%;
}
body.home .home-our-experts .experts-wrapper .card .bio-wrapper {
  margin-top: 18px;
  width: 100%;
}
body.home .home-our-experts .experts-wrapper .card .bio-wrapper .name {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  text-align: left;
  text-transform: capitalize;
  font-size: 32px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card .bio-wrapper .name {
    text-align: center;
  }
}
body.home .home-our-experts .experts-wrapper .card .bio-wrapper .name a {
  color: #FFFFFF;
  text-decoration: none;
}
body.home .home-our-experts .experts-wrapper .card .bio-wrapper .name a:hover {
  text-decoration: underline;
}
body.home .home-our-experts .experts-wrapper .card .publications {
  width: 100%;
  margin-top: 5px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card .publications {
    background-color: #CE2127;
    padding: 5px 5% 20px;
  }
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card .publications .publication {
    padding: 10px 15px;
  }
}
body.home .home-our-experts .experts-wrapper .card .publications .publication hr {
  height: 2px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card .publications .publication:first-child hr {
    display: none;
  }
}
body.home .home-our-experts .experts-wrapper .card .publications .publication .type {
  font-style: italic;
  font-size: 16px;
  text-transform: capitalize;
  font-family: apple garamond, garamond, serif;
  margin: 20px 0 8px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card .publications .publication .type {
    text-align: center;
  }
}
body.home .home-our-experts .experts-wrapper .card .publications .publication .title a {
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
}
body.home .home-our-experts .experts-wrapper .card .publications .publication .title a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .card .publications .publication .title a {
    text-align: center;
  }
}
body.home .home-our-experts .experts-wrapper .btn.slick-arrow {
  display: flex;
  position: absolute;
  top: 215px;
  padding: 0;
  margin: 0;
  z-index: 9;
}
body.home .home-our-experts .experts-wrapper .btn.slick-arrow.prev {
  left: -60px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .btn.slick-arrow.prev {
    left: 0px;
  }
}
body.home .home-our-experts .experts-wrapper .btn.slick-arrow.next {
  right: -60px;
}
@media screen and (max-width: 786px) {
  body.home .home-our-experts .experts-wrapper .btn.slick-arrow.next {
    right: 0px;
  }
}
body.home .home-our-experts .experts-wrapper .btn.slick-arrow i {
  background-color: #4D4D4D;
  padding: 13px;
  color: #FFF;
  height: 40px;
  align-self: center;
  font-size: 30px;
  display: table;
}
body.home .home-featured-experts {
  overflow-y: visible;
  padding: 0;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 120px;
}
@media screen and (max-width: 786px) {
  body.home .home-featured-experts {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 786px) {
  body.home .home-featured-experts .wp-block-columns {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
}
body.home .home-featured-experts h2 {
  color: #FFFFFF;
  margin-bottom: 35px;
  margin-top: 50px;
}
body.home .home-featured-experts .experts-left-col {
  padding-left: 6% !important;
}
body.home .home-featured-experts .experts-right-col {
  margin-bottom: -100px;
  padding-right: 6% !important;
}
body.home .home-featured-experts .expert {
  display: flex;
  align-items: center;
}
body.home .home-featured-experts .expert .headshot {
  overflow: hidden;
  border-radius: 100%;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
}
body.home .home-featured-experts .expert.featured {
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media screen and (max-width: 1000px) {
  body.home .home-featured-experts .expert.featured {
    flex-direction: column;
  }
}
body.home .home-featured-experts .expert.featured .headshot-wrapper {
  width: 300px;
  height: 300px;
  margin: 0 50px;
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  flex: 0 0 300px;
}
body.home .home-featured-experts .expert.featured .headshot-wrapper .headshot {
  width: 280px;
  height: 280px;
  margin: auto;
}
body.home .home-featured-experts .expert.featured .bio-wrapper {
  padding-right: 20px;
}
body.home .home-featured-experts .expert.featured .bio-wrapper .name {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 10px;
  text-transform: none;
}
@media screen and (max-width: 1000px) {
  body.home .home-featured-experts .expert.featured .bio-wrapper .name {
    text-align: center;
  }
}
body.home .home-featured-experts .expert.featured .bio-wrapper .position {
  font-family: NeueHelveticaPro75Bold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
  font-style: normal;
  font-family: NeueHelveticaPro55Roman, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
}
@media screen and (max-width: 1000px) {
  body.home .home-featured-experts .expert.featured .bio-wrapper .position {
    text-align: center;
  }
}
body.home .home-featured-experts .expert.featured .bio-wrapper .button {
  background-color: #CE2127;
  color: #FFFFFF;
  border-radius: 6px;
  padding: 12px 22px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 300;
}
@media screen and (max-width: 1000px) {
  body.home .home-featured-experts .expert.featured .bio-wrapper .button {
    margin: 0 auto;
    display: block;
    float: none;
    width: 170px;
    display: block;
  }
}
body.home .home-featured-experts .expert .name {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
}
body.home .home-featured-experts .expert .name a {
  font-family: apple garamond, garamond, serif;
  text-decoration: none;
  color: inherit;
}
body.home .home-featured-experts .expert .position {
  font-family: apple garamond, garamond, serif;
  font-style: italic;
  font-size: 22px;
  font-weight: 100;
}
body.home .home-featured-experts hr.wp-block-separator {
  border-color: #FFF;
  border-width: 2.5px;
}

#call-to-action {
  width: 100%;
  background-color: #FFFFFF;
  margin: 90px auto 0;
  padding: 90px 10%;
}
@media screen and (max-width: 1000px) {
  #call-to-action {
    margin: 40px auto;
    padding: 30px 4%;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 650px) {
  #call-to-action {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1000px) {
  #call-to-action .row {
    flex-direction: column;
  }
}
#call-to-action .row > div {
  align-self: center;
}
@media screen and (min-width: 650px) {
  #call-to-action .row > div:nth-child(even) {
    padding-left: 30px;
  }
}
#call-to-action .row > div:nth-child(odd) {
  padding-right: 30px;
}
#call-to-action .row > div .ctct-form-wrapper a.ctct-button, #call-to-action .row > div .ctct-form-wrapper .ctct-disclosure {
  display: none;
}
#call-to-action .row > div .ctct-form-wrapper form.clc-sign-up {
  display: flex;
}
@media screen and (max-width: 650px) {
  #call-to-action .row > div .ctct-form-wrapper form.clc-sign-up {
    flex-direction: column;
  }
}
#call-to-action .row > div .ctct-form-wrapper form.clc-sign-up p.ctct-form-field.ctct-form-field-email {
  flex: 1;
}
#call-to-action .row > div .ctct-form-wrapper form.clc-sign-up p.ctct-form-field.ctct-form-field-email input.ctct-email {
  width: 97%;
  border-radius: 0;
  border: 1px solid #393E42;
  height: 50px;
  padding: 10px 20px;
}
@media screen and (max-width: 650px) {
  #call-to-action .row > div .ctct-form-wrapper form.clc-sign-up p.ctct-form-field.ctct-form-field-email input.ctct-email {
    width: 100%;
  }
}
#call-to-action .row > div .ctct-form-wrapper form.clc-sign-up p.ctct-form-field.ctct-form-field-submit {
  flex: 0 0 150px;
}
@media screen and (max-width: 650px) {
  #call-to-action .row > div .ctct-form-wrapper form.clc-sign-up p.ctct-form-field.ctct-form-field-submit {
    flex: unset;
  }
}
#call-to-action .row > div .ctct-form-wrapper form.clc-sign-up p.ctct-form-field.ctct-form-field-submit input.ctct-submit {
  background-color: #CE2127;
  color: #FFFFFF;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 300;
}
#call-to-action .row .cta-text {
  font-size: 35px;
  color: #CE2127;
  text-align: right;
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
}
@media screen and (max-width: 1000px) {
  #call-to-action .row .cta-text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
  }
}

.research-slider-wrapper, .blog-slider-wrapper {
  position: unset;
}
.research-slider-wrapper h2.wp-block-heading, .blog-slider-wrapper h2.wp-block-heading {
  padding-left: 90px;
}
@media screen and (max-width: 1600px) {
  .research-slider-wrapper h2.wp-block-heading, .blog-slider-wrapper h2.wp-block-heading {
    padding-left: 6%;
  }
}
@media screen and (max-width: 1000px) {
  .research-slider-wrapper h2.wp-block-heading, .blog-slider-wrapper h2.wp-block-heading {
    margin-top: 50px;
  }
}

h2.slider-header {
  margin-left: 5%;
  padding-left: 10px;
}
@media screen and (max-width: 786px) {
  h2.slider-header {
    margin-left: 0;
    padding-left: 0;
  }
}

.scrolling-list {
  position: relative;
}
@media screen and (max-width: 1000px) {
  .scrolling-list {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 650px) {
  .scrolling-list {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
}
.scrolling-list#research_slider .wrapper .card, .scrolling-list#publications_slider .wrapper .card {
  min-height: 500px;
  flex: 0 0 25%;
}
.scrolling-list#research_slider .wrapper .card .date, .scrolling-list#publications_slider .wrapper .card .date {
  margin-bottom: 20px;
}
.scrolling-list#publications_slider .wrapper .card hr {
  background-color: #ce2127;
}
.scrolling-list#publications_slider .wrapper .card .title {
  font-size: 22px;
  font-weight: bold;
}
.scrolling-list#publications_slider .wrapper .card .title a {
  text-decoration: none;
  color: inherit;
}
.scrolling-list#publications_slider .wrapper .card .desc {
  padding: 0 24px;
  font-size: 18px;
}
.scrolling-list#publications_slider .wrapper .card .tags {
  margin-top: 10px;
}
.scrolling-list a.button.browse-all {
  margin-top: -85px;
}
.scrolling-list .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: unset;
}
@media screen and (max-width: 650px) {
  .scrolling-list .wrapper {
    margin: 0 50px;
  }
}
.scrolling-list .wrapper.no-slider {
  justify-content: center;
}
.scrolling-list .wrapper.no-slider .card {
  flex: 0 0 23%;
  min-width: 350px;
}
.scrolling-list .wrapper .card {
  cursor: pointer;
  border-radius: 0;
  padding: 0;
  margin: 0 10px;
  box-shadow: 2px 2px 7px -4px #666;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 650px) {
  .scrolling-list .wrapper .card {
    margin: 0;
  }
}
.scrolling-list .wrapper .card .image {
  width: 100%;
  height: 415px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: center top;
}
.scrolling-list .wrapper .card .image a {
  width: 100%;
  height: 100%;
  display: block;
}
.scrolling-list .wrapper .card .tags {
  font-size: 0;
  padding: 0 24px;
}
.scrolling-list .wrapper .card hr {
  background-color: #2F3133;
  border-color: transparent;
  height: 5px;
  margin: 24px;
}
.scrolling-list .wrapper .card .date {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  font-size: 20px;
  padding: 0 24px;
  margin-bottom: 0px;
}
.scrolling-list .wrapper .card .title {
  padding: 0 24px;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 24px;
}
.scrolling-list .wrapper .card .title a {
  font-family: NeueHelveticaPro75Bold, Helvetica, "Helvetica", "Source Sans Pro", sans-serif;
  text-decoration: none;
  color: inherit;
}
.scrolling-list .wrapper .card .desc {
  padding: 0 24px;
}
.scrolling-list .wrapper .card .author {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  font-size: 20px;
  padding: 0 24px;
  margin-bottom: 20px;
}
.scrolling-list .wrapper .slick-list {
  width: calc(100% - 80px);
  margin: 0 80px;
}
@media screen and (max-width: 650px) {
  .scrolling-list .wrapper .slick-list {
    width: 100%;
    margin: 0;
  }
}
.scrolling-list .wrapper .btn.slick-arrow {
  position: absolute;
  z-index: 1;
  top: 44%;
  background-color: #2F3133;
  border-radius: 0;
  color: #FFF;
  padding: 20px 26px;
  font-size: 30px;
  top: 50%;
  width: 80px;
  height: 80px;
  transform: translate(0, -50%);
}
@media screen and (max-width: 650px) {
  .scrolling-list .wrapper .btn.slick-arrow {
    width: 45px;
    height: 45px;
    padding: 9px;
    font-size: 22px;
  }
}
.scrolling-list .wrapper .btn.slick-arrow.prev {
  left: 0;
}
.scrolling-list .wrapper .btn.slick-arrow.next {
  right: 0;
}

.latest-blogs-row {
  overflow: visible;
}
.latest-blogs-row #blog_slider {
  flex-wrap: wrap;
}
.latest-blogs-row #blog_slider .card {
  flex: 0 0 23%;
}
.latest-blogs-row #blog_slider .card hr {
  border-color: #CE2127;
}

@media screen and (max-width: 1000px) {
  a.button.browse-all {
    position: absolute;
    bottom: 0px; /* -80px */
    width: 40%;
    text-align: center;
    left: 30%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 650px) {
  a.button.browse-all {
    width: 60%;
    left: 20%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

a.button.browse-all, button.facetwp-load-more {
  border-radius: 6px;
  border: 1px solid #CE2127;
  text-transform: uppercase;
  color: #CE2127;
  padding: 12px 80px 8px;
  float: right;
  text-decoration: none;
  font-size: 17px;
  margin-right: 6%;
}

button.facetwp-load-more {
  background-color: transparent;
  margin: 40px auto;
  display: block;
  float: none;
}

#subpage_list {
  margin-top: -100px;
}
#subpage_list .card {
  display: block;
  flex: 0 0 48%;
  padding: 0;
  border-radius: 0;
  border: none;
  margin-bottom: 50px;
  box-shadow: 0px 0px 15px -7px #666;
}
@media screen and (max-width: 782px) {
  #subpage_list .card {
    flex: 0 0 96%;
  }
}
#subpage_list .card .image {
  min-height: 270px;
}
#subpage_list .card .image a {
  width: 100%;
  min-height: 270px;
  display: block;
}
#subpage_list .card .title {
  float: left;
}
@media screen and (max-width: 650px) {
  #subpage_list .card .title {
    width: 70%;
  }
}
#subpage_list .card .title a {
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px;
  display: block;
  color: #4D4D4D;
  text-decoration: none;
  font-weight: bold;
}
@media screen and (max-width: 650px) {
  #subpage_list .card .title a {
    font-size: 25px;
    padding: 29px 16px;
    line-height: 30px;
  }
}
#subpage_list .card .arrow {
  background-color: #CE2127;
  float: right;
}
@media screen and (max-width: 650px) {
  #subpage_list .card .arrow {
    width: 30%;
  }
}
#subpage_list .card .arrow a {
  padding: 40px;
  color: #FFFFFF;
  display: block;
  font-size: 30px;
}
@media screen and (max-width: 650px) {
  #subpage_list .card .arrow a {
    padding: 30px 20px;
    text-align: center;
    line-height: 22px;
  }
}

.history-video-section {
  position: relative;
  z-index: 2;
}
.history-video-section.wp-block-columns .wp-block-columns {
  margin-left: 5%;
}
.history-video-section.wp-block-columns .wp-block-columns .wp-block-embed iframe {
  width: 100%;
  height: 350px;
}

.red-graphic-section {
  position: relative;
  z-index: 1;
}

.original-co-authors.wp-block-cover {
  margin-top: -100px;
  z-index: 0;
  position: relative;
  padding-top: 100px;
}
.original-co-authors.wp-block-cover h2 {
  margin-bottom: 30px;
}
.original-co-authors.wp-block-cover hr.wp-block-separator {
  border-color: #393E42;
  margin-top: 0;
  opacity: 1;
}

.team-list .wrapper .card {
  background-color: transparent;
  border: none;
  margin-bottom: 60px;
}
.team-list .wrapper .card:hover .headshot-wrapper {
  border: 1px solid #CE2127;
}
@media screen and (max-width: 1000px) {
  .team-list .wrapper .card {
    box-shadow: none;
    height: unset;
  }
}
.team-list .wrapper .card .headshot-wrapper {
  margin-bottom: 40px;
  width: 85%;
  margin: 0 auto;
  border-radius: 100%;
  border: 1px solid transparent;
}
.team-list .wrapper .card .headshot-wrapper .headshot {
  transform: scale(0.95);
  display: block;
  max-width: 300px;
  width: 100%;
  padding: 50%;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  background-color: #EFEFEF;
  background-repeat: no-repeat;
  background-position: center center;
}
.team-list .wrapper .card:hover .bio-wrapper .name a {
  color: #CE2127;
}
.team-list .wrapper .card .bio-wrapper .name {
  font-size: 25px;
  margin-bottom: 0;
}
@media screen and (max-width: 1000px) {
  .team-list .wrapper .card .bio-wrapper .name {
    text-align: center;
  }
}
.team-list .wrapper .card .bio-wrapper .name a {
  text-decoration: none;
  color: #4D4D4D;
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
}
.team-list .wrapper .card .bio-wrapper .position {
  font-size: 15px;
  font-weight: 500;
  color: #4D4D4D;
}
@media screen and (max-width: 1000px) {
  .team-list .wrapper .card .bio-wrapper .position {
    text-align: center;
  }
}

#endorsements .endorsement {
  background-color: #FFFFFF;
  padding: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  width: 48%;
  margin-right: 1%;
  display: inline-block;
  margin-bottom: 20px;
}
@media screen and (max-width: 786px) {
  #endorsements .endorsement {
    width: 100%;
    margin-right: 0%;
  }
}
#endorsements .endorsement:nth-child(even) {
  margin-left: 1%;
}
#endorsements .endorsement .title {
  color: #CE2127;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: bold;
}
#endorsements .endorsement .desc {
  font-size: 20px;
  color: #393E42;
}
#endorsements .endorsement hr {
  background-color: #4D4D4D;
  height: 4px;
  margin: 20px 0 30px;
}
#endorsements .endorsement .date, #endorsements .endorsement .publication {
  font-size: 20px;
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  color: #393E42;
}

.page-nav.wp-block-buttons {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-evenly !important;
}
.page-nav.wp-block-buttons .wp-block-button {
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 650px) {
  .page-nav.wp-block-buttons .wp-block-button {
    width: 100%;
  }
}
.page-nav.wp-block-buttons .wp-block-button .wp-block-button__link {
  font-weight: bold;
  font-size: 20px;
  padding: 10px 100px 10px 15px !important;
  text-transform: uppercase;
}
@media screen and (max-width: 650px) {
  .page-nav.wp-block-buttons .wp-block-button .wp-block-button__link {
    text-align: left;
  }
}
.page-nav.wp-block-buttons .wp-block-button .wp-block-button__link::after {
  content: " ";
  display: block;
  width: 60px;
  height: 60px;
  background-color: #4D4D4D;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="%23ffffff" d="M174.6 472.6c4.5 4.7 10.8 7.4 17.4 7.4s12.8-2.7 17.4-7.4l168-176c9.2-9.6 8.8-24.8-.8-33.9s-24.8-8.8-33.9 .8L216 396.1 216 56c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 340.1L41.4 263.4c-9.2-9.6-24.3-9.9-33.9-.8s-9.9 24.3-.8 33.9l168 176z"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.single-team .headshow-wrapper {
  margin: -80px auto 0;
  display: block;
  width: 420px;
  height: 420px;
  border: 1px solid red;
  border-radius: 100%;
  overflow: hidden;
  padding: 9px;
}
@media screen and (max-width: 650px) {
  .single-team .headshow-wrapper {
    width: 320px;
    height: 320px;
  }
}
.single-team .headshow-wrapper .headshot {
  width: 400px;
  height: 400px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 100%;
  overflow: hidden;
}
@media screen and (max-width: 650px) {
  .single-team .headshow-wrapper .headshot {
    width: 300px;
    height: 300px;
  }
}
.single-team .sidebar .header {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: #2F3133;
  margin-bottom: 20px;
}
.single-team .sidebar a.back-to.back-to-staff {
  color: #4D4D4D;
  text-transform: uppercase;
  font-weight: 600;
}
.single-team .sidebar a.back-to.back-to-staff i {
  margin-right: 2px;
}
.single-team .sidebar .position {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  font-size: 25px;
  color: #CE2127;
  margin-bottom: 30px;
  line-height: 110%;
}
@media screen and (max-width: 650px) {
  .single-team .sidebar .linkedin {
    float: right;
    margin: -20px 0 20px;
  }
}
.single-team .sidebar .linkedin a {
  width: 50px;
  height: 50px;
  display: block;
  border: 1px solid #CE2127;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}
.single-team .sidebar .linkedin a i {
  font-size: 24px;
  color: #CE2127;
}

.wp-block-social-links li.wp-social-link {
  margin-right: 5px;
}
.wp-block-social-links li.wp-social-link a {
  border: 1px solid #CE2127;
  border-radius: 100%;
  padding: 9px !important;
}
.wp-block-social-links li.wp-social-link a svg {
  fill: #CE2127 !important;
  font-size: 24px;
}

.red-box-shadow img {
  box-shadow: 100px 70px 0 0 #CE2127;
}

.blue-box-shadow img {
  box-shadow: 100px 70px 0 0 #0D6EA6;
}

@media screen and (max-width: 650px) {
  .our-values .wp-block-column {
    text-align: center;
  }
}
.our-values hr.wp-block-separator {
  border-width: 0.5px;
  margin-bottom: 40px;
}

#jobopenings .job {
  background-color: #FFFFFF;
  box-shadow: 0 0 10px -5px #666;
  margin-bottom: 50px;
  padding: 30px;
}
#jobopenings .job:last-child {
  margin-bottom: 0;
}
#jobopenings .job .title {
  color: #CE2127;
  font-weight: bold;
  margin-bottom: 10px;
}
#jobopenings .job hr {
  border-color: #2F3133;
  margin-bottom: 30px;
  border-width: 2.5px;
}
#jobopenings .job button a {
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
}

.single-job .sidebar .field.notice {
  font-size: 16px;
  font-style: italic;
}

.social-wrapper {
  margin-top: 25px;
}
.social-wrapper .heateor_sss_sharing_ul a {
  margin-right: 10px !important;
}
.social-wrapper .heateor_sss_sharing_ul a .heateor_sss_svg {
  background-color: #FFFFFF !important;
  border: 1px solid #CE2127 !important;
  width: 40px !important;
  height: 40px !important;
  padding: 5px !important;
}
.social-wrapper .heateor_sss_sharing_ul a .heateor_sss_svg svg path {
  fill: #CE2127 !important;
}

.single-event .speakers .header {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  color: #CE2127;
  font-size: 28px;
  margin-bottom: 10px;
}
.single-event .speakers .headshot {
  width: 150px;
  height: 150px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}
.single-event .speakers .name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  color: #2F3133;
  margin-bottom: 5px;
}
.single-event .speakers .title {
  color: #707070;
  font-size: 20px;
}
.single-event .embed {
  margin-top: 60px;
}
.single-event .sidebar .field {
  text-transform: uppercase;
  font-weight: bold;
}

a.back-to {
  display: block;
  margin-top: 20px;
  color: #CE2127;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
}
a.back-to i {
  margin-left: -8px;
  margin-right: 8px;
}

.sidebar .header {
  font-family: apple garamond, garamond, serif;
  color: #CE2127;
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 100;
  margin-bottom: 20px;
}
.sidebar .field {
  color: #707070;
  font-size: 16px;
  margin-bottom: 10px;
}

.events-page .tabs {
  margin-top: -90px;
}
@media screen and (max-width: 650px) {
  .events-page .tabs {
    margin-top: -130px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    align-content: space-around;
  }
}
.events-page .tabs .tab {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  background-color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px 0;
  color: #4D4D4D;
  cursor: pointer;
}
@media screen and (max-width: 650px) {
  .events-page .tabs .tab {
    width: 70%;
  }
}
.events-page .tabs .tab.current {
  background-color: #CE2127;
  color: white;
}
.events-page .tab-content .card {
  background-color: #FFFFFF;
  border: none;
  border-radius: 0;
  box-shadow: 0px 0px 10px -5px #666;
  padding: 0;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
@media screen and (max-width: 650px) {
  .events-page .tab-content .card {
    flex-direction: column;
  }
}
.events-page .tab-content .card .image {
  flex: 0 0 300px;
  height: 300px;
  background-color: #EFEFEF;
}
.events-page .tab-content .card .content {
  flex: 1;
  padding: 30px 50px;
}
@media screen and (max-width: 650px) {
  .events-page .tab-content .card .content {
    padding: 30px;
  }
}
.events-page .tab-content .card .content .title {
  color: #CE2127;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 10px;
}
.events-page .tab-content .card .content .title a {
  color: inherit;
  text-decoration: none;
}
.events-page .tab-content .card .content .desc {
  font-size: 18px;
  margin-bottom: 20px;
  color: #393E42;
}
.events-page .tab-content .card .content hr {
  border-color: #4D4D4D;
  margin: 30px 0;
}
.events-page .tab-content .card .content .date, .events-page .tab-content .card .content .address {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  color: #393E42;
}
.events-page .tab-content .card .content button.wp-block-button {
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  float: right;
}
@media screen and (max-width: 650px) {
  .events-page .tab-content .card .content button.wp-block-button {
    float: left;
    padding-left: 0;
    margin-top: 10px;
  }
}

.border-bottom {
  border-bottom: 5px solid #2F3133 !important;
}

.thin-black {
  border-color: #000 !important;
  border-width: 0.5px !important;
}

.partner-icon.wp-block-media-text {
  display: block;
  grid-template-columns: unset !important;
  grid-template-rows: unset !important;
  float: left;
  width: 100%;
}
.partner-icon.wp-block-media-text figure {
  grid-column: unset !important;
  grid-row: unset !important;
  float: left;
}
.partner-icon.wp-block-media-text figure img {
  width: 24px !important;
  height: 24px !important;
}
.partner-icon.wp-block-media-text .wp-block-media-text__content {
  margin-top: 2px;
}
@media screen and (max-width: 1300px) and (min-width: 1001px) {
  .partner-icon.wp-block-media-text .wp-block-media-text__content {
    margin-left: 5px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 786px) {
  .partner-icon.wp-block-media-text .wp-block-media-text__content {
    margin-left: 10px;
  }
}

.partner-icon-legend {
  display: flex;
}
.partner-icon-legend .wp-block-media-text__content {
  padding-left: 15px !important;
  float: left;
}
.partner-icon-legend .wp-block-media-text__content p {
  margin-bottom: 0;
}

.parent-our-solutions .why-it-matters > .row {
  background-color: #EFEFEF;
  margin: 100px 0;
}
.parent-our-solutions .why-it-matters > .row .icon {
  width: 230px;
  height: 230px;
  padding: 65px;
  margin-top: -30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 786px) {
  .parent-our-solutions .why-it-matters > .row .icon {
    position: absolute;
    left: 0;
    z-index: 3;
    padding: 30px;
    width: 130px;
    height: 130px;
  }
}
@media screen and (max-width: 575px) {
  .parent-our-solutions .why-it-matters > .row .icon {
    width: 100px;
    height: 100px;
    padding: 10px;
  }
}
.parent-our-solutions .why-it-matters > .row .icon img {
  width: 100px;
  height: 100px;
}
@media screen and (max-width: 786px) {
  .parent-our-solutions .why-it-matters > .row .icon img {
    width: 75px;
    height: 75px;
  }
}
.parent-our-solutions .why-it-matters > .row .content-wrapper {
  background-color: #FFFFFF;
  padding: 28px;
  margin-left: 20px;
  margin-top: 50px;
  border-bottom: 4px solid #2F3133;
  margin-bottom: -30px;
}
@media screen and (max-width: 786px) {
  .parent-our-solutions .why-it-matters > .row .content-wrapper {
    margin-left: 0;
  }
}
.parent-our-solutions .why-it-matters > .row .content-wrapper .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 15px;
}
@media screen and (max-width: 786px) {
  .parent-our-solutions .content-grid-spacer {
    display: none;
  }
}
@media screen and (max-width: 786px) {
  .parent-our-solutions .image-offset-box.wp-block-column {
    margin: 0 -6% !important;
    padding: 90px 30px;
  }
}
@media screen and (max-width: 786px) {
  .parent-our-solutions .image-offset-box.wp-block-column figure.wp-block-image {
    float: right;
    max-width: 50%;
    margin-right: -4%;
    margin-top: -150px;
    margin-bottom: -90px;
    margin-left: 0;
  }
}
.parent-our-solutions .scrolling-list {
  margin-bottom: -175px;
}
.parent-our-solutions .scrolling-list#blog_slider .wrapper .btn.slick-arrow {
  top: unset;
  bottom: 143px;
  transform: unset;
}
.parent-our-solutions .scrolling-list#research_slider .wrapper .btn.slick-arrow {
  top: unset;
  bottom: 160px;
  transform: unset;
}
.parent-our-solutions .scrolling-list a.button.browse-all {
  bottom: -60px;
}
.parent-our-solutions #blog_slider.scrolling-list .wrapper .card hr {
  border-width: 2.5px;
}
.parent-our-solutions .research-slider-wrapper h2.wp-block-heading, .parent-our-solutions .blog-slider-wrapper h2.wp-block-heading {
  margin-bottom: 30px;
}

.image-offset-box.wp-block-column {
  margin-left: 70px;
  margin-top: 70px;
  margin-right: -70px;
  padding: 30px;
}
.image-offset-box.wp-block-column figure.wp-block-image {
  margin-left: -160px;
  margin-top: -180px;
}
@media screen and (max-width: 786px) {
  .image-offset-box.wp-block-column hr {
    display: none;
  }
}
.image-offset-box.wp-block-column .wp-block-media-text {
  width: 200px;
}
@media screen and (max-width: 786px) {
  .image-offset-box.wp-block-column .wp-block-media-text {
    display: flex;
    flex-direction: column;
  }
}
.image-offset-box.wp-block-column .wp-block-media-text .wp-block-media-text__content {
  padding-left: 0;
}
@media screen and (max-width: 786px) {
  .image-offset-box.wp-block-column .wp-block-media-text .wp-block-media-text__content {
    align-self: flex-start;
  }
}
@media screen and (max-width: 786px) {
  .image-offset-box.wp-block-column .wp-block-media-text .wp-block-media-text__media {
    align-self: flex-start;
  }
}
@media screen and (max-width: 786px) {
  .image-offset-box.wp-block-column .wp-block-media-text .wp-block-media-text__media img {
    width: 50px;
  }
}
@media screen and (max-width: 650px) {
  .image-offset-box.wp-block-column .wp-block-media-text .wp-block-media-text__media img {
    margin: 20px 0;
  }
}
.image-offset-box.wp-block-column p {
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
}
.image-offset-box.wp-block-column p a {
  text-decoration: none;
}
.image-offset-box.wp-block-column hr {
  border-color: #FFFFFF;
  margin-bottom: 20px;
  border-width: 2px;
}

.signatories .row .col-3 {
  margin: 15px 0;
}
.signatories .name {
  font-size: 27px;
  color: #4D4D4D;
  margin-bottom: 5px;
}
.signatories .position {
  font-size: 18px;
  color: #4D4D4D;
  font-weight: bold;
}

.listing-template .report.card, .listing-template .news.card, .listing-template .search-result.card {
  border: none;
  border-radius: 0px;
  box-shadow: 0 0 14px -8px #666;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
}
.listing-template .report.card .image-wrapper, .listing-template .news.card .image-wrapper, .listing-template .search-result.card .image-wrapper {
  background-color: #EFEFEF;
  width: 300px;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .listing-template .report.card .image-wrapper, .listing-template .news.card .image-wrapper, .listing-template .search-result.card .image-wrapper {
    display: none;
  }
}
.listing-template .report.card .image-wrapper .image, .listing-template .news.card .image-wrapper .image, .listing-template .search-result.card .image-wrapper .image {
  display: block;
  width: 100%;
  height: 100%;
}
.listing-template .report.card .image-wrapper .image a, .listing-template .news.card .image-wrapper .image a, .listing-template .search-result.card .image-wrapper .image a {
  display: block;
  width: 100%;
  height: 100%;
}
.listing-template .report.card .content-wrapper, .listing-template .news.card .content-wrapper, .listing-template .search-result.card .content-wrapper {
  flex: 1;
  padding: 30px;
}
.listing-template .report.card .content-wrapper .tags, .listing-template .news.card .content-wrapper .tags, .listing-template .search-result.card .content-wrapper .tags {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .listing-template .report.card .content-wrapper .tags, .listing-template .news.card .content-wrapper .tags, .listing-template .search-result.card .content-wrapper .tags {
    display: none;
  }
}
.listing-template .report.card .content-wrapper .title, .listing-template .news.card .content-wrapper .title, .listing-template .search-result.card .content-wrapper .title {
  color: #CE2127;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}
.listing-template .report.card .content-wrapper .title a, .listing-template .news.card .content-wrapper .title a, .listing-template .search-result.card .content-wrapper .title a {
  color: inherit;
  text-decoration: none;
}
.listing-template .report.card .content-wrapper .desc, .listing-template .news.card .content-wrapper .desc, .listing-template .search-result.card .content-wrapper .desc {
  font-size: 18px;
}
.listing-template .report.card .content-wrapper .desc a, .listing-template .news.card .content-wrapper .desc a, .listing-template .search-result.card .content-wrapper .desc a {
  color: inherit;
  text-decoration: none;
}
.listing-template .report.card .content-wrapper hr, .listing-template .news.card .content-wrapper hr, .listing-template .search-result.card .content-wrapper hr {
  border-color: #2F3133;
  margin: 20px 0;
}
.listing-template .report.card .content-wrapper .date, .listing-template .report.card .content-wrapper .author, .listing-template .news.card .content-wrapper .date, .listing-template .news.card .content-wrapper .author, .listing-template .search-result.card .content-wrapper .date, .listing-template .search-result.card .content-wrapper .author {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
}
.listing-template .report.card .content-wrapper .date, .listing-template .news.card .content-wrapper .date, .listing-template .search-result.card .content-wrapper .date {
  margin-bottom: 0;
}

.tags a {
  display: inline-block;
  font-size: 14px;
  background-color: #2F3133;
  color: #FFFFFF;
  border-radius: 20px;
  text-decoration: none;
  padding: 5px 12px 3px;
  margin: 0 10px 10px 0;
}

.sidebar-wrapper {
  background-color: #EFEFEF;
  padding: 20px;
  width: 90%;
  margin-top: -90px;
}
@media screen and (max-width: 768px) {
  .sidebar-wrapper {
    width: 104%;
    margin: -6px -2% 30px;
  }
}
.sidebar-wrapper .header {
  text-transform: uppercase;
  font-weight: bold;
  color: #4D4D4D;
  margin-bottom: 10px;
}
.sidebar-wrapper .facetwp-facet {
  margin-bottom: 20px;
}
.sidebar-wrapper .facetwp-facet .facetwp-input-wrap {
  width: 100%;
}
.sidebar-wrapper .facetwp-facet input.facetwp-search {
  background-color: transparent;
  border: none;
  border-bottom: 5px solid #2F3133;
  padding: 10px 10px 10px 35px !important;
  min-width: unset;
  width: 100%;
}
.sidebar-wrapper .facetwp-facet i.facetwp-icon {
  right: unset;
  left: 0;
  opacity: 1;
}
.sidebar-wrapper .facetwp-facet i.facetwp-icon:before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="26.686" height="27.256" viewBox="0 0 26.686 27.256"><path id="noun-search-icon-3670603-FFFFFF" d="M273.2,263.5c-1.794-1.885-3.679-3.724-5.5-5.518-.772-.749-1.521-1.5-2.294-2.248.068-.114.136-.227.2-.318a9.911,9.911,0,1,0-18.416-5.087v.023a9.878,9.878,0,0,0,14.669,8.652l4.019,4.019c.431.431.885.908,1.317,1.362.885.908,1.771,1.862,2.748,2.7a2.129,2.129,0,0,0,1.476.59A2.515,2.515,0,0,0,273.2,263.5Zm-18.689-5.722a7.92,7.92,0,1,1,8.7-2.43,7.947,7.947,0,0,1-8.7,2.43Zm17.258,7.721a.39.39,0,0,1-.454.091,2.074,2.074,0,0,1-.5-.5,3.935,3.935,0,0,0-.272-.318l-6.949-6.949c.2-.182.409-.363.59-.545l7.335,7.335a1,1,0,0,0,.159.136,1.7,1.7,0,0,1,.2.2.533.533,0,0,1-.114.545Z" transform="translate(-247.195 -240.425)" fill="%23cd1e25"/></svg>');
}
.sidebar-wrapper .facetwp-facet span.facetwp-counter {
  display: none;
}
.sidebar-wrapper .facetwp-facet .facetwp-display-value {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
}
.sidebar-wrapper button.facetwp-reset {
  border: 1px solid #CE2127;
  border-radius: 6px;
  color: #CE2127;
  padding: 10px 40px;
  text-transform: uppercase;
}

.single-report .thumbnail img, .single-blog .thumbnail img {
  width: 90%;
  margin: 40px 0;
  display: block;
  box-shadow: 0px 0px 15px -7px #666;
}
.single-report div .author, .single-blog div .author {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  color: #CE2127;
  font-size: 28px;
  margin-bottom: 10px;
}
.single-report div .originally-published, .single-blog div .originally-published {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 25px;
  color: #4D4D4D;
}
.single-report div .content, .single-blog div .content {
  margin-bottom: 60px;
}
.single-report div .report-links, .single-blog div .report-links {
  margin-bottom: 30px;
}
.single-report div .report-links .report-link a, .single-blog div .report-links .report-link a {
  text-transform: uppercase;
  font-weight: 300;
}
.single-report div hr.wp-block-separator, .single-blog div hr.wp-block-separator {
  border-width: 0.5px;
}
.single-report div .key-takeaways, .single-blog div .key-takeaways {
  margin-bottom: 60px;
}
.single-report div .key-takeaways .header, .single-blog div .key-takeaways .header {
  font-weight: bold;
  text-transform: uppercase;
  color: #2F3133;
  font-size: 24px;
  margin-bottom: 10px;
}
.single-report div .downloads .downloads-link, .single-blog div .downloads .downloads-link {
  margin-bottom: 20px;
}
.single-report div .downloads .downloads-link a, .single-blog div .downloads .downloads-link a {
  width: 250px;
}
.single-report .author, .single-blog .author {
  color: red;
  margin-bottom: 15px;
  font-size: 28px;
}
.single-report .date, .single-blog .date {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #2F3133;
}

.back-to-publications {
  margin-bottom: 10px;
  margin-top: 0;
}

.single-post .author, .single-appearance .author {
  font-family: apple garamond, garamond, serif;
  font-weight: 100;
  color: #CE2127;
  margin-bottom: 15px;
  font-size: 28px;
}
.single-post .date, .single-appearance .date {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #4D4D4D;
}
.single-post .content, .single-appearance .content {
  color: #707070;
}

.content .wp-block-embed iframe {
  min-height: 600px;
}

a.wp-block-button__link.wp-element-button {
  padding: 17px 20px 14px !important;
}
a.wp-block-button__link.wp-element-button:hover {
  color: inherit;
}
a.wp-block-button__link.wp-element-button.outline {
  background-color: #FFFFFF;
  border: 1px solid #CE2127;
  color: #CE2127;
}

.single-appearance .video_link {
  text-align: center;
  margin: 35px 0;
}
.single-appearance .social-wrapper {
  float: right;
}
.single-appearance a.back-to {
  float: left;
}
.single-appearance .tags {
  float: left;
  margin: 27px 0 -20px 20px;
}

.single-press_release .sidebar .tags, .single-op_ed .sidebar .tags {
  margin: 30px 0;
}
.single-press_release .sidebar .contact-info, .single-op_ed .sidebar .contact-info {
  color: #2F3133;
  font-weight: bold;
  margin-bottom: 30px;
}
.single-press_release .sidebar .note, .single-op_ed .sidebar .note {
  font-style: italic;
  color: #707070;
}
.single-press_release .sidebar .field, .single-op_ed .sidebar .field {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #2F3133;
  margin-bottom: 20px;
}
.single-press_release .sidebar .logo, .single-op_ed .sidebar .logo {
  margin: 50px 0 40px;
}
.single-press_release .sidebar .logo img, .single-op_ed .sidebar .logo img {
  width: 100%;
}
.single-press_release .originally-published, .single-op_ed .originally-published {
  color: #4D4D4D;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.single-op_ed .authors {
  font-family: apple garamond, garamond, serif;
  color: #CE2127;
  font-size: 24px;
}
.single-op_ed .download-link.wp-block-button {
  float: right;
}
.single-op_ed .download-link.wp-block-button a {
  text-transform: uppercase;
}

.center-for-climate-and-trade header.header a.center {
  font-weight: 800;
  border-bottom: 6px solid red;
}
@media screen and (max-width: 786px) {
  .center-for-climate-and-trade .center-cta {
    text-align: center;
  }
}
.center-for-climate-and-trade main.main .main-content {
  padding-top: 0;
  padding-bottom: 0;
}
.center-for-climate-and-trade main.main .main-content .hero {
  margin-top: 0;
}
.center-for-climate-and-trade main.main .main-content .hero .wp-block-columns {
  margin-left: 6% !important;
}
@media screen and (max-width: 1000px) {
  .center-for-climate-and-trade .center-nav.page-nav.wp-block-buttons {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .center-for-climate-and-trade .image-offset-box {
    display: none;
  }
}
.center-for-climate-and-trade .ctct-form-wrapper p.ctct-form-field.ctct-form-field-email {
  text-align: right;
}
.center-for-climate-and-trade .ctct-form-wrapper p.ctct-form-field.ctct-form-field-email input.ctct-email {
  border-radius: 0;
  padding: 5px 9px;
  border: 1px solid #2F3133;
}
.center-for-climate-and-trade .ctct-form-wrapper p.ctct-form-field.ctct-form-field-submit {
  text-align: right;
}
.center-for-climate-and-trade .ctct-form-wrapper p.ctct-form-field.ctct-form-field-submit input.ctct-submitted {
  background-color: #38375B;
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  padding: 8px 25px;
}
.center-for-climate-and-trade .ctct-form-wrapper .ctct-disclosure {
  display: none;
}
.center-for-climate-and-trade .sidebar-wrapper {
  align-self: flex-start;
}

.donate-buttons-wrapper .wp-block-button button.wp-block-button__link {
  width: 100%;
  background-color: transparent !important;
}

cite {
  font-style: normal;
  color: #4D4D4D;
}

.contact-form-wrapper form input.wpcf7-form-control.wpcf7-submit {
  background-color: #CE2127;
  border: none;
  color: #FFFFFF;
  padding: 10px 25px;
  text-transform: uppercase;
  border-radius: 6px;
}

.search-results-page .search-box-wrapper span.facetwp-input-wrap {
  width: 100%;
}
.search-results-page .search-box-wrapper span.facetwp-input-wrap input.facetwp-search {
  width: 100%;
  border-radius: 0px;
  border: 1px solid #000;
  padding: 10px 15px;
  font-size: 20px;
}
.search-results-page .facetwp-type-pager {
  text-align: center;
}
.search-results-page .facetwp-type-pager button.facetwp-load-more {
  background-color: transparent;
  color: #CE2127;
  border: 1px solid #CE2127;
  border-radius: 6px;
  padding: 10px 70px;
  margin: 40px auto 0;
}

@media screen and (max-width: 650px) {
  .contact-form {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
}
.contact-form .row {
  padding: 8px 0;
}
@media screen and (max-width: 925px) {
  .contact-form .row {
    display: block;
  }
}
@media screen and (max-width: 650px) {
  .contact-form .row {
    padding: 15px 0;
  }
}
.contact-form .row:nth-child(even) {
  background-color: #EFEFEF;
}
.contact-form .row p {
  margin-bottom: 0;
  color: #2F3133 !important;
}
.contact-form .row .wpcf7-form-control {
  width: 100%;
}
.contact-form .row input.wpcf7-form-control.wpcf7-submit {
  margin: 0 auto;
  display: block;
}
.contact-form .row span.wpcf7-not-valid-tip {
  background-color: red;
  color: white;
  display: inline-block;
  padding: 0px 6px;
  border-radius: 16px;
  text-transform: uppercase;
  font-size: 11px;
  float: right;
  margin-top: -24px;
  z-index: 1;
  position: relative;
}

.has-data-link {
  cursor: pointer;
}

.latest-research-row {
  margin-bottom: 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.latest-research-row .wp-block-columns {
  width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;
}

@media screen and (max-width: 768px) {
  .sidebar-wrapper .collapsible {
    border-bottom: 1px solid #000;
    margin-bottom: 13px;
  }
  .sidebar-wrapper .collapsible:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .sidebar-wrapper .collapsible .header {
    margin: 0;
    padding: 15px 0;
    cursor: pointer;
  }
}
.sidebar-wrapper .collapsible .header i {
  float: right;
  font-size: 25px;
  transform: rotate(90deg);
  transition: transform 0.2s;
}
@media screen and (min-width: 767px) {
  .sidebar-wrapper .collapsible .header i {
    display: none;
  }
}
.sidebar-wrapper .collapsible .header.show i {
  transform: rotate(0);
  transition: transform 0.2s;
}
@media screen and (max-width: 768px) {
  .sidebar-wrapper .collapsible .body {
    display: none;
  }
}
.sidebar-wrapper .collapsible .body.show {
  display: block !important;
}